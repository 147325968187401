import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";

import styles from "./styles.module.scss";
import DeliveryTimeList from "../../components/DeliveryTimeList";
import AddressFinderAutoComplete from "../../components/AddressFinderAutoComplete";
import { GET_LOCATIONS } from "../../queries/locations";
import { GET_COUPONS } from "../../queries/coupons";
import { GET_TAGGABLE_ORDER } from "../../queries/me";
import {
  gqErrorMsg,
  isDeliveryDay,
  isValidAddress,
  minOrderDate,
  translateError
} from "../../lib";
import {
  setDeliveryTimeId,
  setDeliveryDate,
  setDeliveryTimeRange,
  setDeliveryCost,
  setCanonicalAddressId,
  setFullAddress,
  setPaymentIntent,
  setaddressAPIMetaData,
  setPendingOrder,
  setQRCode,
  clearCheckoutMeta,
  setCoupons,
  setNote,
  setDeliveryTimesPerLocation,
  setLocation,
  setAvailableCoupons,
  clearCheckout,
  setPaidOrder,
} from "./actions";
import { setCart, setCartTotal } from "../../components/CartFloating/actions";
import { CHECKOUT } from "../../mutations/checkout";
import historySingleton from "../../lib/history";
import OrderSummary from "../../components/OrderSummary";
import DeliverySummary from "../../components/DeliverySummary";
import GenericLoader from "../../components/GenericLoader";
import GenericAlert from "../../components/GenericAlert";
import SelectCoupon from "../../components/SelectCoupon";
import PaymentSuccess from "../../components/Payment/Success";

function NextToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey);
  return (
    <Button
      className={`btn ${styles.nextBtn}`}
      type="button"
      variant="gradient"
      name="next"
      onClick={decoratedOnClick}
    >
      {children}
    </Button>
  );
}

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      taggableOrder: null
    };
  }

  componentDidMount() {
    this.props.client
      .query({ query: GET_COUPONS, variables: { checkout: true } })
      .then(res => {
        const coupons = _.get(res, "data.coupons", []);
        if (coupons.length > 0) {
          this.props.setAvailableCoupons(coupons);
        } else {
          this.props.setAvailableCoupons([]);
        }
      })
      .catch(({ graphQLErrors }) => {
        console.log(gqErrorMsg(graphQLErrors));
      });
  }

  componentWillUnmount() {
    this.clearForm();
  }

  queryLocations = ({ canonicalAddressId, meta, fullAddress }) => {
    this.props.client
      .query({ query: GET_LOCATIONS, variables: { fullAddress } })
      .then(res => {
        const location = _.get(res, "data.locations[0]");
        if (location) {
          this.setState({ error: null });
          this.props.setLocation(location);
          this.groupDeliveryTimes(location.contextedDeliveryTimes);
          this.props.setFullAddress(fullAddress);
          this.props.setCanonicalAddressId(canonicalAddressId);
          this.props.setaddressAPIMetaData(meta);
        } else {
          this.setState({
            error:
              "Sorry, the delivery service is not available in your area yet."
          });
        }
      })
      .catch(({ graphQLErrors }) => {
        this.setState({
          error: gqErrorMsg(graphQLErrors)
        });
      });
  };

  // Group by Morning Afternoon Evening
  groupDeliveryTimes = deliveryTimes => {
    this.props.setDeliveryTimesPerLocation(
      _.groupBy(deliveryTimes, "timeOfDay")
    );
  };

  handleSetDeliveryTime = (id, price, totalPrice, discount, timeRange) => {
    this.setState({ error: null });
    this.props.setDeliveryTimeId(id);
    this.props.setDeliveryTimeRange(timeRange);
    this.props.setDeliveryCost(price, totalPrice, discount);
  };

  handleSetDeliveryDate = date => {
    this.setState({ taggableOrder: null, loading: true });

    this.props.client
      .query({
        query: GET_TAGGABLE_ORDER,
        variables: {
          deliveryAddress: this.props.fullAddress,
          deliveryDate: moment(date).format("DD/MM/YYYY")
        }
      })
      .then(res => {
        this.setState({ loading: false });
        const taggableOrder = _.get(res, "data.taggableOrder", null);
        if (taggableOrder) {
          this.setState({ taggableOrder });
        }
      })
      .catch(({ graphQLErrors }) => {
        this.setState({ loading: false });
        console.log(gqErrorMsg(graphQLErrors));
      });
    this.props.setDeliveryDate(moment(date).format("DD/MM/YYYY"));
  };

  handleAddressSelection = (canonicalAddressId, fullAddress, meta) => {
    this.clearForm();
    this.queryLocations({ canonicalAddressId, meta, fullAddress });
  };

  clearForm = () => {
    this.props.clearCheckoutMeta();
    this.setState({ error: null });
  };

  onPendingOrderCreated = ({ order, paymentIntent, qrCode }) => {
    this.props.setPaymentIntent(paymentIntent);
    this.props.setPendingOrder(order);
    this.props.setQRCode(qrCode);
    historySingleton.push("/payment");
  }

  onPaidOrderCreated = ({order}) => {
    this.props.setCart([]);
    this.props.setCartTotal(0, 0);
    this.props.clearCheckout();
    this.props.setPaidOrder(order);
  }

  handleCheckout = () => {
    const {
      client,
      deliveryTimeId,
      deliveryDate,
      canonicalAddressId,
      fullAddress,
      selectedCoupons,
      note,
      location
    } = this.props;

    if (_.isNull(deliveryTimeId)) {
      return this.setState({ error: "Please choose a delivery time slot" });
    }

    if (_.isNull(deliveryDate)) {
      return this.setState({ error: "Please choose a delivery date" });
    }

    if (_.isNull(fullAddress)) {
      return this.setState({ error: "Please choose an address" });
    }

    this.setState({ loading: true });

    client
      .mutate({
        mutation: CHECKOUT,
        variables: {
          partial: true,
          deliveryTimeId,
          deliveryDate,
          canonicalAddressId,
          fullAddress,
          couponIds: selectedCoupons.map((c) => c.id),
          note,
          locationId: location.id
        }
      })
      .then(res => {
        const paymentIntent = _.get(
          res,
          "data.checkout.checkout.paymentIntent",
          null
        );
        const order = _.get(res, "data.checkout.checkout.order", null);
        const qrCode = _.get(res, "data.checkout.checkout.qrCode", null);

        if (order.status === "paid") {
          this.onPaidOrderCreated({order});
        } else {
          this.onPendingOrderCreated({order, qrCode, paymentIntent});
        }
      })
      .catch(({ graphQLErrors }) => {
        // replace with redux
        this.setState({
          error: gqErrorMsg(graphQLErrors),
          loading: false
        });
      });
  };

  renderDeliveryTimeList = () => {
    const {
      deliveryTimeId,
      deliveryTimesPerLocation,
      cartTotals: { cartTotalPrice }
    } = this.props;
    return Object.keys(deliveryTimesPerLocation).map((timeOfDay, index) => {
      return (
        <Col
          md="auto"
          className={styles.deliveryTimeList}
          key={`${timeOfDay}-${index}`}
        >
          <DeliveryTimeList
            cartTotalPrice={cartTotalPrice}
            timeOfDay={timeOfDay}
            handleSetDeliveryTime={this.handleSetDeliveryTime}
            deliveryTimes={deliveryTimesPerLocation[timeOfDay]}
            selectedTimeId={deliveryTimeId}
            taggableOrder={this.state.taggableOrder}
          />
        </Col>
      );
    });
  };

  renderDeliveryTimes = () => {
    const {
      fullAddress,
      t,
      deliveryDate,
      location,
      activeFeatures
    } = this.props;
    if (fullAddress) {
      return (
        <div className={styles.datePicker}>
          <h3>{t(["checkout.step2.title"])}</h3>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            minDate={minOrderDate(
              location.deadline,
              location.deadlineMeridiem,
              activeFeatures
            )}
            maxDate={moment()
              .add(8, "days")
              .toDate()}
            filterDate={d =>
              isDeliveryDay(d, _.get(location, "deliveryDays", []))
            }
            selected={
              deliveryDate
                ? Date.parse(moment(deliveryDate, "DD/MM/YYYY"))
                : null
            }
            onChange={this.handleSetDeliveryDate}
          />
          <p>{t("deliveryDateNote")}</p>
          <Row>{this.renderDeliveryTimeList()}</Row>
          <NextToggle eventKey={"2"}>{t("next")}</NextToggle>
        </div>
      );
    } else {
      return <h3>{t(["checkout.step2.selectAddressError"])}</h3>;
    }
  };

  onErrorclose = () => this.setState({ error: null });

  renderSelectCoupon = () => {
    const {
      canonicalAddressId,
      deliveryTimeId,
      deliveryDate,
      selectedCoupons,
      setCoupons,
      availableCoupons
    } = this.props;
    if (
      canonicalAddressId &&
      deliveryTimeId &&
      deliveryDate &&
      availableCoupons.length > 0
    ) {
      return (
        <SelectCoupon
          coupons={availableCoupons}
          selectedCoupons={selectedCoupons}
          setCoupons={setCoupons}
        />
      );
    }
    return null;
  };

  renderNoteField = () => {
    return (
      <>
        <Form.Group>
          <Form.Label>{this.props.t(["checkout.step3.title"])}</Form.Label>
          <Form.Control
            defaultValue={this.props.note}
            as="textarea"
            rows="3"
            maxLength="1000"
            onChange={e => this.props.setNote(e.target.value)}
          />
        </Form.Group>
        <NextToggle eventKey={"3"}>{this.props.t("next")}</NextToggle>
      </>
    );
  };

  renderNote = () => {
    const { note, t } = this.props;
    if (note) {
      return (
        <div className={styles.noteWrapper}>
          <ListGroup variant="flush">
            <h4>{t(["checkout.step4.note"])}</h4>
            <ListGroup.Item>
              <Row>
                <Col>{note}</Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </div>
      );
    }
  };

  render() {
    const { error, loading, taggableOrder } = this.state;
    const {
      cartItems,
      cartTotals,
      deliveryTimeId,
      deliveryDate,
      deliveryTimeRange,
      deliveryCost,
      canonicalAddressId,
      addresses,
      fullAddress,
      me,
      t,
      selectedCoupons,
      note,
      paidOrder
    } = this.props;
    const { name, email, phone } = me;
    const { cartTotalPrice } = cartTotals;
    const deliveryPrice = _.get(deliveryCost, "price", 0);
    const deliveryDiscount =
      cartTotalPrice > 12000 || taggableOrder
        ? 100
        : _.get(deliveryCost, "discount", 0);
    const deliveryTotalPrice =
      cartTotalPrice > 12000 || taggableOrder
        ? 0
        : _.get(deliveryCost, "totalPrice", 0);

    const cartTotal = cartTotalPrice + deliveryTotalPrice;

    if (paidOrder) {
      return <PaymentSuccess />
    }

    return (
      <Container className={styles.checkoutWrapper}>
        <Accordion defaultActiveKey="0">
          <Card variant="checkoutStep">
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              className={canonicalAddressId && styles.completedStep}
            >
              {t(["checkout.step1.heading"])}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Row className="mb-3">
                  <Col>
                    <h2 className={styles.checkoutAddressMain}>
                      {t(["checkout.step1.deliveringTo"])}{" "}
                      <span>{fullAddress}</span>
                    </h2>
                  </Col>
                </Row>
                <Row>
                  {!_.isEmpty(addresses) && (
                    <Col xs={{ order: 1, span: 12 }} lg={{ order: 1, span: 6 }}>
                      <h4>{t(["checkout.step1.recentAddresses"])}</h4>
                      <div className={styles.recentAddressesWrapper}>
                        <ListGroup>
                          {addresses.map(address => {
                            const _canonicalAddressId = _.get(
                              address,
                              "canonicalAddressId",
                              null
                            );
                            const _fullAddress = _.get(
                              address,
                              "fullAddress",
                              null
                            );
                            return (
                              <ListGroup.Item
                                key={_canonicalAddressId}
                                {...(canonicalAddressId === _canonicalAddressId
                                  ? { variant: "primary" }
                                  : {})}
                                action
                                onClick={() =>
                                  this.handleAddressSelection(
                                    _canonicalAddressId,
                                    _fullAddress
                                  )
                                }
                              >
                                {_fullAddress}
                              </ListGroup.Item>
                            );
                          })}
                        </ListGroup>
                      </div>
                    </Col>
                  )}
                  <Col xs={{ order: 2, span: 12 }} lg={{ order: 2, span: 6 }}>
                    <h4>{t(["checkout.step1.newAddress"])}</h4>
                    <AddressFinderAutoComplete
                      fullAddress={fullAddress}
                      handleAddressCancel={this.clearForm}
                      handleAddressSelection={this.handleAddressSelection}
                    />
                    <p>{t(["checkout.step1.note"])}</p>
                    {error && (
                      <GenericAlert closeCallback={this.onErrorclose}>
                        {error}
                      </GenericAlert>
                    )}
                    <NextToggle eventKey={"1"}>{t(["next"])}</NextToggle>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className={styles.step2Card}>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="1"
              className={deliveryTimeId && deliveryDate && styles.completedStep}
            >
              {t(["checkout.step2.heading"])}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>{this.renderDeliveryTimes()}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="2"
              className={styles.completedStep}
            >
              {t(["checkout.step3.heading"])}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>{this.renderNoteField()}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              {t(["checkout.step4.heading"])}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <DeliverySummary
                  name={name}
                  email={email}
                  phone={phone}
                  deliveryDate={deliveryDate}
                  deliveryTimeRange={deliveryTimeRange}
                  deliveryAddress={fullAddress}
                  deliveryOriginalPrice={deliveryPrice}
                  deliveryDiscount={deliveryDiscount}
                  deliveryFinalPrice={deliveryTotalPrice}
                />
                {this.renderNote()}
                <OrderSummary
                  orderItems={cartItems}
                  deliveryFee={deliveryTotalPrice}
                  subtotal={cartTotalPrice}
                  totalPrice={cartTotal}
                  coupons={selectedCoupons}
                  note={note}
                />
                {this.renderSelectCoupon()}
                <div className={styles.payBtnWrapper}>
                  <Button
                    className={styles.payBtn}
                    type="button"
                    variant="gradient"
                    name="next"
                    onClick={this.handleCheckout}
                    disabled={loading}
                  >
                    {t(["checkout.step4.pay"])}
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => historySingleton.push("/")}
                  >
                    {t(["invoice.continueShopping"])}
                  </Button>
                </div>
                {loading && <GenericLoader />}
                {error && (
                  <GenericAlert closeCallback={this.onErrorclose}>
                    {translateError(t, error)}
                  </GenericAlert>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    deliveryTimeId: _.get(state.checkoutReducer, "meta.deliveryTimeId", null),
    deliveryDate: _.get(state.checkoutReducer, "meta.deliveryDate", null),
    deliveryTimeRange: _.get(
      state.checkoutReducer,
      "meta.deliveryTimeRange",
      null
    ),
    deliveryCost: _.get(state.checkoutReducer, "meta.deliveryCost", null),
    canonicalAddressId: _.get(
      state.checkoutReducer,
      "meta.canonicalAddressId",
      null
    ),
    me: _.get(state.meReducer, "me", null),
    activeFeatures: _.get(state.meReducer, "me.activeFeatures", []),
    addresses: _.get(state.meReducer, "me.addresses", []),
    fullAddress: _.get(state.checkoutReducer, "meta.fullAddress", null),
    cartItems: _.get(state.cartReducer, "cartItems", []),
    cartTotals: _.get(state.cartReducer, "cartTotals", null),
    selectedCoupons: _.get(state.checkoutReducer, "selectedCoupons", []),
    note: _.get(state.checkoutReducer, "note", null),
    deliveryTimesPerLocation: _.get(
      state.checkoutReducer,
      "deliveryTimesPerLocation",
      {}
    ),
    location: _.get(state.checkoutReducer, "location", null), // used for picking date
    availableCoupons: _.get(state.checkoutReducer, "availableCoupons", []),
    paidOrder: _.get(state.checkoutReducer, "paidOrder"),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setDeliveryTimeId,
      setDeliveryDate,
      setDeliveryTimeRange,
      setDeliveryCost,
      setCanonicalAddressId,
      setFullAddress,
      setPaymentIntent,
      setPendingOrder,
      setQRCode,
      setaddressAPIMetaData,
      clearCheckoutMeta,
      clearCheckout,
      setCoupons,
      setNote,
      setDeliveryTimesPerLocation,
      setLocation,
      setAvailableCoupons,
      setCartTotal,
      setCart,
      setPaidOrder,
    },
    dispatch
  );

// To promote a component to a container (smart component) - it needs
// to know about this new dispatch method. Make it available
// as a prop.
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Checkout)
);
