export const SET_ME = "SET_ME";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_DEFAULT_CATEGORIES = "SET_DEFAULT_CATEGORIES";
export const SET_CLIENT = "SET_CLIENT";

export function setMe(payload) {
  return {
    type: SET_ME,
    payload
  };
}

export function setProducts(payload) {
  return { type: SET_PRODUCTS, payload };
}

export function setCategories(payload) {
  return { type: SET_CATEGORIES, payload };
}

export function setDefaultCategories(payload) {
  return { type: SET_DEFAULT_CATEGORIES, payload };
}

export function setClient(client) {
  return { type: SET_CLIENT, payload: client };
}
