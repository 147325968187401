import gql from "graphql-tag";

export const GET_COUPONS = gql`
  query coupons($checkout: Boolean) {
    coupons(checkout: $checkout) {
      id
      name
      cn
      kind
      status
      startedAt
      expiredAt
      value
      percentage
      product {
        id
        price
        discountedPrice
      }
    }
  }
`;
