import gql from "graphql-tag";

export const CONFIRM_PAYMENT = gql`
  mutation confirmPayment($orderNumber: String!) {
    confirmPayment(input: { orderNumber: $orderNumber }) {
      status
      cartTotalPrice
      cartTotalOriginalPrice
      cartItems {
        name
        variant
        price
        discount
        totalPrice
        quantity
        notes
        id
        orderId
        images
        quantityBundleName
        quantityBundleCn
        quantityBundleQuantity
        quantityBundlePercentage
      }
      order {
        id
        orderNumber
        totalPrice
        status
        paidAt
        deliveryPrice
        deliveryTimeFrom
        deliveryTimeTo
        deliveryTimeId
        deliveryAddress
        couponPrice
        note
        orderItems {
          id
          notes
          name
          cn
          variant
          quantity
          price
          discount
          totalPrice
          quantityBundleName
          quantityBundleCn
          quantityBundleQuantity
          quantityBundlePercentage
        }
      }
    }
  }
`;
