import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import _ from "lodash";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

import CartFloating from "../../../components/CartFloating";
import QuantityBundles from "../../../components/QuantityBundles";
import { GET_PRODUCTS } from "../../../queries/products";
import { gqErrorMsg, isEn, stockRibbon } from "../../../lib";
import history from "../../../lib/history";
import GenericLoader from "../../../components/GenericLoader";
import AddToCart from "../../../components/AddToCart";
import DiscountPrice from "../../../components/DiscountPrice";
import GenericCarousel from "../../../components/GenericCarousel";

import styles from "./styles.module.scss";

class Show extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productItem: {
        id: "",
        name: "",
        stock: 0,
        shorthand: "",
        featured: false,
        topSeller: false,
        price: 0,
        images: "",
        description: "",
        descCn: "",
        discount: 0,
        quantityBundles: []
      },
      cartItems: [],
      loading: true
    };
  }

  componentDidMount() {
    this.fetchProductFromMatchParam();
  }

  componentDidUpdate(prevProps) {
    if (
      _.get(this.props, "match.params.productId", null) !==
      _.get(prevProps, "match.params.productId", null)
    ) {
      this.fetchProductFromMatchParam();
    }
  }

  fetchProductFromMatchParam = () => {
    const { client, match } = this.props;
    const id = _.get(match, "params.productId", null);

    client
      .mutate({
        mutation: GET_PRODUCTS,
        variables: { id }
      })
      .then(res => {
        const productItem = _.get(res, "data.products[0]", null);
        this.setState({ productItem, loading: false });
      })
      .catch(({ graphQLErrors }) => {
        // replace with redux
        this.setState({
          loading: false,
          error: gqErrorMsg(graphQLErrors)
        });
      });
  };

  renderTabs = () => {
    const { t } = this.props;
    const { productItem } = this.state;

    return (
      <Row>
        <Col className={styles.tabWrapper} lg={{ offset: 3, span: 6 }}>
          <Tabs defaultActiveKey="description">
            <Tab eventKey="description" title={t(["products.desc"])}>
              <p
                dangerouslySetInnerHTML={{
                  __html: isEn() ? productItem.description : productItem.descCn
                }}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    );
  };

  render() {
    const { cartItems, t } = this.props;
    const { productItem, loading } = this.state;

    const productName = isEn()
      ? _.get(productItem, "name", "")
      : _.get(productItem, "cn", "");
    const productImages = _.get(productItem, "images", []);

    if (loading)
      return (
        <div className={styles.loadingWrapper}>
          <GenericLoader />
        </div>
      );

    const cartItem = cartItems.find(
      item => parseInt(productItem.id) === item.productId
    );

    const slides = productImages.map(img => {
      return {
        src: img.replace("w_300", ""),
        alt: productItem.name,
        cta: { link: img }
      };
    });

    return (
      <div className={styles.wrapper}>
        <Container>
          <Row>
            <Col lg={6}>
              <div className={styles.productImageWrap}>
                <GenericCarousel slides={slides} />
              </div>
            </Col>
            <Col lg={6}>
              <Card>
                <Card.Body>
                  <h2>{productName}</h2>
                  <p>
                    <span className={styles.label}>{`${t([
                      "products.productCode"
                    ])}:`}</span>
                    <span>{`${productItem.shorthand || "N/A"}`}</span>
                  </p>
                  <hr />
                  <div className={styles.productPrice}>
                    <DiscountPrice
                      originalPrice={productItem.price}
                      discount={productItem.discount}
                    />
                  </div>
                  {stockRibbon(productItem.stock, t, true)}
                  <hr />
                  <div>
                    <span className={styles.label}>
                      {t("quantityBundles")}:
                    </span>
                    {productItem.quantityBundles.length > 0
                      ? productItem.quantityBundles.map(qb => {
                          return (
                            <div key={`qb${qb.id}`}>
                              {isEn() ? qb.name : qb.cn}
                            </div>
                          );
                        })
                      : "N/A"}
                  </div>
                  <hr />
                  <div className={styles.footerWrapper}>
                    <AddToCart
                      showText={true}
                      showIcon={false}
                      cartItem={cartItem}
                      productItem={productItem}
                    />
                    <Button
                      variant="gradient"
                      onClick={() => history.push("/checkout")}
                    >
                      <FontAwesomeIcon icon={faShoppingCart} />
                      {"  "}
                      {t(["checkout.title"])}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {this.renderTabs()}
        </Container>
        <CartFloating />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    me: _.get(state.meReducer, "me", {}),
    cartItems: _.get(state.cartReducer, "cartItems", [])
  };
};

export default withTranslation()(connect(mapStateToProps)(Show));
