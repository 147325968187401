import React from "react";
import PropTypes from "prop-types";
import DeliveryTimeItem from "../DeliveryTimeItem";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSun,
  faCloudSun,
  faMoon,
  faUtensils
} from "@fortawesome/free-solid-svg-icons";

const deliveryTimeItems = (
  deliveryTimes,
  handleSetDeliveryTime,
  selectedTimeId,
  cartTotalPrice,
  taggableOrder
) =>
  deliveryTimes.map((time, index) => (
    <DeliveryTimeItem
      key={`deliveryTimeItem-${index}`}
      {...time}
      handleSetDeliveryTime={handleSetDeliveryTime}
      selectedTimeId={selectedTimeId}
      cartTotalPrice={cartTotalPrice}
      taggableOrder={taggableOrder}
    />
  ));

const DeliveryTimeList = ({
  timeOfDay,
  deliveryTimes,
  handleSetDeliveryTime,
  selectedTimeId,
  cartTotalPrice,
  taggableOrder
}) => {
  return (
    <div>
      <h4 className={styles.timeOfDayHeading}>
        {DeliveryTimeList.iconMap(timeOfDay)}
        {timeOfDay}
      </h4>
      {deliveryTimeItems(
        deliveryTimes,
        handleSetDeliveryTime,
        selectedTimeId,
        cartTotalPrice,
        taggableOrder
      )}
    </div>
  );
};

DeliveryTimeList.iconMap = timeOfDay => {
  switch (timeOfDay) {
    case "morning":
      return <FontAwesomeIcon size="lg" icon={faSun} />;
    case "afternoon":
      return <FontAwesomeIcon size="lg" icon={faCloudSun} />;
    case "evening":
      return <FontAwesomeIcon size="lg" icon={faMoon} />;
    default:
      return <FontAwesomeIcon size="lg" icon={faUtensils} />;
  }
};

DeliveryTimeList.propTypes = {
  timeOfDay: PropTypes.string
};

DeliveryTimeList.defaultProps = {
  timeOfDay: ""
};

export default DeliveryTimeList;
