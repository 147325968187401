import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCut } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";

import Coupon from "../Coupon";
import styles from "./styles.module.scss";
import { couponDisplayValue, isEn } from "../../lib";

const SelectCoupon = ({ coupons, setCoupons, selectedCoupons }) => {
  const { t } = useTranslation();

  if (coupons.length === 0) {
    return null;
  }

  const onClick = (coupon) => {
    // Remove already selected coupon, like toggle
    if (selectedCoupons.map(i => i.id).includes(coupon.id)) {
      setCoupons(selectedCoupons.filter(i => i.id !== coupon.id));
    }
    // Cannot have two coupons of the same kind
    if (!selectedCoupons.map(i => i.kind).includes(coupon.kind)) {
      setCoupons(selectedCoupons.concat(coupon));
    }
  }

  return (
    <Col xs={{ order: 1, span: 12 }} lg={{ order: 1, span: 6 }}>
      <h4>{t(["checkout.step4.selectCoupon"])}</h4>
      <div className={styles.couponWrapper}>
        <ListGroup>
          {coupons.map(coupon => {
            return (
              <ListGroup.Item
                key={`coupon-${coupon.id}`}
                {...(selectedCoupons.map(i => i.id).includes(coupon.id)
                  ? { variant: "primary" }
                  : {})}
                action
                onClick={() => onClick(coupon)}
              >
                <Coupon coupon={coupon} />
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </div>
    </Col>
  );
};

SelectCoupon.defaultProps = {
  coupons: [],
  setCoupon: f => f,
  selectedCoupon: null
};

SelectCoupon.propTypes = {
  coupons: PropTypes.array,
  setCoupon: PropTypes.func,
  selectedCoupon: PropTypes.object
};

export default SelectCoupon;
