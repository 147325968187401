import React, { Component } from "react";
import PropTypes from "prop-types";
import Counter from "../Counter";
import { ADD_TO_CART, UPDATE_CART } from "../../mutations/cart";
import _ from "lodash";
import { gqErrorMsg } from "../../lib";
import { bindActionCreators } from "redux";
import { setCart, setCartTotal } from "../CartFloating/actions";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import history from "../../lib/history";
import Alert from "react-bootstrap/Alert";
import styles from "./styles.module.scss";

class AddToCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null
    };
  }

  handleAddToCart = productItem => {
    const { client, me } = this.props;
    if (!_.get(me, "id")) {
      return history.push("/login");
    }

    client
      .mutate({
        mutation: ADD_TO_CART,
        variables: { productId: productItem.id, quantity: 1 }
      })
      .then(res => {
        const cartItems = _.get(res, "data.addToCart.cartItems", []);
        const cartTotalOriginalPrice = _.get(
          res,
          "data.addToCart.cartTotalOriginalPrice",
          0
        );
        const cartTotalPrice = _.get(res, "data.addToCart.cartTotalPrice", 0);
        this.props.setCart(cartItems);
        this.props.setCartTotal(cartTotalOriginalPrice, cartTotalPrice);
      })
      .catch(({ graphQLErrors }) => {
        // replace with redux
        this.setState({
          loading: false,
          error: gqErrorMsg(graphQLErrors)
        });
      });
  };

  handleUpdateCart = (cartItemId, quantity) => {
    const { client } = this.props;
    this.setState({ loading: true });

    client
      .mutate({
        mutation: UPDATE_CART,
        variables: { orderItemId: parseInt(cartItemId), quantity }
      })
      .then(res => {
        const cartItems = _.get(res, "data.updateCart.cartItems", []);
        const cartTotalOriginalPrice = _.get(
          res,
          "data.updateCart.cartTotalOriginalPrice",
          0
        );
        const cartTotalPrice = _.get(res, "data.updateCart.cartTotalPrice", 0);
        this.props.setCart(cartItems);
        this.props.setCartTotal(cartTotalOriginalPrice, cartTotalPrice);
      })
      .catch(({ graphQLErrors }) => {
        this.setState({
          loading: false,
          error: gqErrorMsg(graphQLErrors)
        });
      });
  };

  renderActionButton = () => {
    const { productItem, cartItem, t, showText, showIcon } = this.props;
    const productItemStock = _.get(productItem, "stock");
    const quantity = _.get(cartItem, "quantity", 0);
    const purchaseLimit = _.get(productItem, "purchaseLimit", 15);
    const cartItemId = _.get(cartItem, "id", null);

    if (quantity === 0 && productItemStock > 0) {
      return (
        <Button
          className={styles.addToCartBtn}
          variant="gradient"
          onClick={() => this.handleAddToCart(productItem)}
        >
          {showIcon && <FontAwesomeIcon icon={faShoppingCart} />}{" "}
          {showText && t("addToCart")}
        </Button>
      );
    } else if (quantity === 0 && productItemStock === 0) {
      return <div className={styles.placeholder}></div>;
    }

    return (
      <Counter
        onUpdateCart={quantity => this.handleUpdateCart(cartItemId, quantity)}
        quantity={cartItem.quantity}
        stock={productItemStock}
        purchaseLimit={purchaseLimit}
      />
    );
  };

  render() {
    const { error } = this.state;

    return (
      <>
        {this.renderActionButton()}
        {error && <Alert variant="danger">{error}</Alert>}
      </>
    );
  }
}

AddToCart.propTypes = {
  quantity: PropTypes.number,
  showText: PropTypes.bool,
  showIcon: PropTypes.bool,
  cartItem: PropTypes.object,
  productItem: PropTypes.object
};

AddToCart.defaultProps = {
  quantity: 0,
  showText: false,
  showIcon: true,
  cartItem: null,
  productItem: null
};

const mapStateToProps = state => {
  return {
    me: _.get(state.meReducer, "me", {}),
    client: _.get(state.appReducer, "client", null)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCart,
      setCartTotal
    },
    dispatch
  );

// To promote a component to a container (smart component) - it needs
// to know about this new dispatch method. Make it available
// as a prop.
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AddToCart)
);
