import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import { gqErrorMsg } from "../../lib";
import { EMAIL_RESET_PASSWORD } from "../../mutations/users";
import GenericLoader from "../../components/GenericLoader";

const ForgotPassword = ({ history }) => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(null);
  const {
    register,
    errors,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm();
  const [resetPassword, { error }] = useMutation(EMAIL_RESET_PASSWORD, {
    onCompleted(data) {
      setSuccess(t(["forgotPassword.success"]));
    }
  });

  const onSubmit = async data => {
    await resetPassword({
      variables: { email: data.email }
    });
  };

  return (
    <Col md={{ span: 6, offset: 3 }}>
      <h1 className="mb-4 mt-5">{t(["forgotPassword.title"])}</h1>
      <form method="POST" onSubmit={handleSubmit(onSubmit)} name="email">
        <Form.Group controlId="email">
          <Form.Label>{t(["login.email"])}</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            name="email"
            ref={register({ required: true })}
          />
          {errors.email && <p className="form-error">This is required</p>}
        </Form.Group>
        {isSubmitting && <GenericLoader />}
        {!success && (
          <Button block type="submit" disabled={isSubmitting}>
            {t(["forgotPassword.title"])}
          </Button>
        )}
        {success && <Alert variant="success">{success}</Alert>}
        {error && <Alert variant="danger">{gqErrorMsg(error)}</Alert>}
      </form>
    </Col>
  );
};

export default ForgotPassword;
