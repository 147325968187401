import React, { useEffect, useState, useRef } from "react";
import Spinner from "react-bootstrap/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";

import styles from "./styles.module.scss";

const PER_PAGE = 6;
const INIT_PAGE = 0;

const CustomInfiniteScroll = ({ allData, customStyle, renderItem }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);

  const fetchMoreData = () => {
    setTimeout(() => {
      setPage(page + 1);
      setData(allData.slice(INIT_PAGE, (page + 1) * PER_PAGE));
    }, 1000);
  };

  useEffect(() => {
    setData(allData.slice(INIT_PAGE, PER_PAGE));
  }, [allData]);

  return (
    <InfiniteScroll
      className={styles.customInfiniteScrollWrapper}
      dataLength={data.length}
      next={fetchMoreData}
      hasMore={data.length < allData.length}
      loader={
        <div className={styles.customDivider}>
          <Spinner animation="border" size="lg" variant="primary" />
        </div>
      }
    >
      <div className={customStyle}>{data.map(item => renderItem(item))}</div>
    </InfiniteScroll>
  );
};

export default CustomInfiniteScroll;
