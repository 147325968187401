import { SET_CART, SET_CART_TOTAL } from "../components/CartFloating/actions";

const DEFAULT_APP_SCHEMA = {
  cartItems: [],
  cartTotals: {
    cartTotalOriginalPrice: 0,
    cartTotalPrice: 0,
  }
};

export default function(state = DEFAULT_APP_SCHEMA, action) {
  switch (action.type) {
    case SET_CART:
      return {
        ...state,
        cartItems: action.payload
      };
    case SET_CART_TOTAL:
      return {
        ...state,
        cartTotals: {
          cartTotalOriginalPrice: action.payload.cartTotalOriginalPrice,
          cartTotalPrice: action.payload.cartTotalPrice,
        }
      };
    default:
      return state;
  }
}
