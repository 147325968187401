import React from "react";
import styles from "./styles.module.scss";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

const CustomArrowButton = props => {
  const { onClick, left } = props;
  const icon = left ? faChevronLeft : faChevronRight;
  const klass = left ? styles.chevronLeft : styles.chevronRight;

  return (
    <div className={`${styles.chevronWrapper} ${klass}`}>
      <Button className={styles.chevronBtn} size="lg" onClick={onClick}>
        <FontAwesomeIcon icon={icon} />
      </Button>
    </div>
  );
}

CustomArrowButton.propTypes = {
  left: PropTypes.bool
}

CustomArrowButton.defaultProps = {
  left: true
}

export default CustomArrowButton;
