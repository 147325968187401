import React from "react";
import styles from "./styles";
import { moneyStr } from "../../lib";

const DiscountPrice = ({ originalPrice, discount, finalPrice }) => {
  let _discount = discount || 0;

  if (finalPrice === 0) {
    _discount = 100;
  }

  if (_discount > 0 && !finalPrice) {
    const _finalPrice = (originalPrice * (100 - discount)) / 100;
    return (
      <div className={styles.discountPriceWrapper}>
        <div className={styles.originalPrice}>${moneyStr(originalPrice)}</div>
        <div className={styles.finalPrice}>${moneyStr(_finalPrice)}</div>
      </div>
    );
  }
  if (originalPrice && finalPrice && originalPrice != finalPrice) {
    return (
      <div className={styles.discountPriceWrapper}>
        <div className={styles.originalPrice}>${moneyStr(originalPrice)}</div>
        <div className={styles.finalPrice}>${moneyStr(finalPrice)}</div>
      </div>
    );
  }
  return (
    <div className={styles.discountPriceWrapper}>
      <div>${moneyStr(finalPrice || originalPrice)}</div>
    </div>
  );
};

export default DiscountPrice;
