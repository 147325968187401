import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";

const En = () => {
  return (
    <>
      <Row>
        <p>
          Established in 2010, AusFreshMeat has been supplying domestic markets with Chicken, Beef, Pork, Lamb, Duck and Seafood. Our factory is located in Flemington, Melbourne, covering an area of 3000 square meters. Our integrated model ensures a streamline supply chain from processing to storage and distribution. Based on advanced product quality control, our core is to supply you with optimum quality produce.
        </p>
        <p>
          <Card.Img src="https://res.cloudinary.com/offbyonex/image/upload/q_auto/v1585747465/afm/p1.png" />
        </p>
        <p>
          AusFreshMeat owns more than ten delivery trucks, as well as qualified drivers, which enables our delivery cover all suburbs in Melbourne. We hope to offer you superior delivery, also aim to supply Australia household with fresh meat products and convenient service.
        </p>
        <p>
          <Card.Img src="https://res.cloudinary.com/offbyonex/image/upload/q_auto/v1585747464/afm/p2.png" />
        </p>
        <p>
          If you want to know more about our company and products, please scan and follow our official accounts (AusFreshMeat). We expect to answer all your questions about our company and products online, as well as provide you with product information based on your needs. Meanwhile, we welcome your valuable suggestions to make us do it better. You can also enquiry via Email, our Email address is info@ausfreshmeat.com.au
        </p>
        <p>
          Thank you for your attention, we will try our best to provide you with perfect products and service.
        </p>
      </Row>
      <Row>
        <h4>Warrook Farm</h4>
        <p>
          On the way of searching for "Fresh”, we showed hesitation and delay on our ideas, also lost our direction several times. After spinning around, we find the answer "Warrook Farm”. The only way to control quality is to have our own farm. During operation of the farm, we realize any problems arising from feed; we also learn how to pick up qualified suppliers. As our benchmark, products from farm also guarantee we have adequate product resource.
        </p>
        <p>
          <Card.Img src="https://res.cloudinary.com/offbyonex/image/upload/q_auto/v1585748314/afm/p4.png" />
        </p>
        <p>
          Warrook Farm located in monomeith, Victoria, covering an area of 1500 acre, feed more than 300 cattle and sheep. We are still exploring more feed choice; hope to provide you with a wide range of fresh farm produce. With its own dining room and banquet hall, Warrook Farm can hold variety of activities and events. Meanwhile, farm-based tourism is also suitable for family style activities. If you are interested, please visit our website www.warrook.com.au for more information.
        </p>
        <p>
          <Card.Img src="https://res.cloudinary.com/offbyonex/image/upload/q_auto/v1585748304/afm/p3.png" />
        </p>
      </Row>
    </>
  );
}

export default En;
