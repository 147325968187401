import gql from "graphql-tag";

export const GET_CATEGORIES = gql`
  query GetCategories($id: ID, $filter: String) {
    categories(id: $id, filter: $filter) {
      id
      name
      cn
      key
      status
      images
    }
  }
`;
