import React from "react";
import styles from "./styles.module.scss";
import Card from "react-bootstrap/Card";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import _ from "lodash";

const ProductCategoryItem = ({ product }) => (
  <div className={styles.productTile}>
    <Card>
      <Card.Header
        data-discount={product.validDiscounts?.length ? "available" : ""}
      >
        {product.validDiscounts?.length ? (
          <div className={styles.ribbon}>
            <span>Sale</span>
          </div>
        ) : null}
        <span>{_.capitalize(product.categoryName)}</span>
      </Card.Header>
      <Card.Body>
        <LinkContainer to={`/product/${product.id}`}>
          <Card.Link>
            {/*<Card.Img src={product.images[0]} />*/}
            <Card.Img src="https://via.placeholder.com/230x150.png?text=Sample+Image" />
          </Card.Link>
        </LinkContainer>
        <Card.Text>
          <LinkContainer to={`/product/${product.id}`}>
            <a>{product.name}</a>
          </LinkContainer>
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <LinkContainer to={"/login"}>
          <Button variant="gradient">Shop now</Button>
        </LinkContainer>
      </Card.Footer>
    </Card>
  </div>
);

ProductCategoryItem.defaultProps = {
  product: {
    id: "167",
    name: "Barramundi 500/900",
    stock: 50,
    featured: false,
    price: 0,
    images: [
      "https://res.cloudinary.com/offbyonex/image/upload/v1565185551/afm/Barramundi.jpg"
    ],
    variant: null,
    quantityUnit: {
      id: "3",
      name: "unit"
    },
    validDiscounts: [
      {
        name: "test",
        percentage: 5
      }
    ]
  }
};

export default ProductCategoryItem;
