import React, { Component } from "react";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";

import GenericCarousel from "../../components/GenericCarousel";
import Banner from "../../components/Banner";
import ProductList from "../../components/ProductList";
import CartFloating from "../../components/CartFloating";
import CategoryTiles from "../../components/CategoryTiles";
import { filterProducts, isEn } from "../../lib";

const cnslides = [
  {
    src:
      "https://res.cloudinary.com/ausfreshmeat/image/upload/q_auto,w_1200,h_500/v1615031301/WechatIMG3423_zjr3gn.jpg",
    alt: "Slide",
    cta: {
      link: "/#",
      label: ""
    },
    caption: {
      title: "",
      summary: ""
    }
  },
  {
    src:
      "https://res.cloudinary.com/offbyonex/image/upload/q_auto,w_1200,h_500/v1587213380/afm/WechatIMG1441.jpg",
    alt: "Slide",
    cta: {
      link: "/#",
      label: ""
    },
    caption: {
      title: "",
      summary: ""
    }
  }
];
const slides = [
  {
    src:
      "https://res.cloudinary.com/ausfreshmeat/image/upload/q_auto,w_1200,h_500/v1615031303/WechatIMG3422_zhjrb4.jpg",
    alt: "Slide",
    cta: {
      link: "/#",
      label: ""
    },
    caption: {
      title: "",
      summary: ""
    }
  },
  {
    src:
      "https://res.cloudinary.com/offbyonex/image/upload/q_auto,w_1200,h_500/v1587213380/afm/WechatIMG1440.jpg",
    alt: "Slide",
    cta: {
      link: "/#",
      label: ""
    },
    caption: {
      title: "",
      summary: ""
    }
  }
];

class Home extends Component {
  render() {
    const { client, products, t, defaultCategories } = this.props;

    const newProducts = filterProducts({ products, filter: "new-products" });
    const topSellers = filterProducts({ products, filter: "top-sellers" });
    const featured = filterProducts({ products, filter: "weekly-specials" });

    const moreFeatureLink = {
      text: t("viewMore"),
      href: "/products/weekly-specials"
    };
    const moreTopLink = { text: t("viewMore"), href: "/products/top-sellers" };
    const moreNewLink = { text: t("viewMore"), href: "/products/new-products" };

    return (
      <>
        <GenericCarousel slides={isEn() ? slides : cnslides} />
        <Col md="12" style={{ margin: "auto" }}>
          <CategoryTiles categories={defaultCategories} />
        </Col>
        {!_.isEmpty(newProducts) && (
          <>
            <ProductList
              client={client}
              products={newProducts}
              title={t("newProducts")}
              moreLink={moreNewLink}
              numberOfCards={6}
              display="PRODUCT"
              direction="TILES"
            />
            <hr />
          </>
        )}
        {!_.isEmpty(featured) && (
          <>
            <ProductList
              client={client}
              products={featured}
              title={t("featuredTitle")}
              moreLink={moreFeatureLink}
              numberOfCards={6}
              display="PRODUCT"
              direction="TILES"
            />
            <hr />
          </>
        )}
        {!_.isEmpty(topSellers) && (
          <ProductList
            client={client}
            products={topSellers}
            title={t("topSellerTitle")}
            moreLink={moreTopLink}
            numberOfCards={6}
            display="PRODUCT"
            direction="TILES"
          />
        )}
        {/* <Banner src="https://res.cloudinary.com/offbyonex/image/upload/q_auto,w_1200,h_350/v1584687929/afm/afm_carousel.jpg" />*/}
        {defaultCategories.map(c => {
          const filteredProducts = filterProducts({ products, filter: c.key });
          if (filteredProducts.length === 0) {
            return <div key={`under-cat-${c.id}`}></div>;
          }
          return (
            <div key={`under-cat-${c.id}`}>
              <ProductList
                client={client}
                products={filteredProducts}
                title={isEn() ? c.name : c.cn}
                moreLink={{ text: t("viewMore"), href: `/products/${c.key}` }}
                display="PRODUCT"
                direction="TILES"
                numberOfCards={6}
                lazy={true}
              />
              <hr />
            </div>
          );
        })}
        <CartFloating client={client} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: _.get(state.productReducer, "products", []),
    defaultCategories: _.get(state.productReducer, "defaultCategories", [])
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

// To promote a component to a container (smart component) - it needs
// to know about this new dispatch method. Make it available
// as a prop.
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Home)
);
