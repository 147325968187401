import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe
} from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.scss";

const LanToggle = () => {
  const { t, i18n } = useTranslation();
  const cookies = new Cookies();

  return (
    <div className={styles.wrapper}>
      <FontAwesomeIcon icon={faGlobe} />
      <a
        onClick={() => {
          cookies.set("lanPreference", "en", { path: "/" });
          i18n.changeLanguage("en");
        }}
      >
        EN
      </a>{" "}
      |{" "}
      <a
        onClick={() => {
          cookies.set("lanPreference", "cn", { path: "/" });
          i18n.changeLanguage("cn");
        }}
      >
        中文
      </a>
    </div>
  );
};

export default LanToggle;
