import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import { capitalize, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import history from "../../lib/history";
import { allProductsCat, isEn } from "../../lib";
import styles from "./styles";
import CustomArrowButton from "../CustomArrowButton";

const CategoryTiles = ({ categories }) => {
  const { t } = useTranslation();
  if (isEmpty(categories)) {
    return null;
  }

  const renderIcon = cat => {
    if (cat.icon) {
      return (
        <div>
          <img src={cat.icon} />
          <span>{isEn() ? capitalize(cat.name) : cat.cn}</span>
        </div>
      );
    }
    return (
      <div className={styles.customIconDiv}>
        {isEn() ? capitalize(cat.name) : cat.cn}
      </div>
    );
  };

  const sliceNum = () => (window.screen.width < 715 ? 5 : 6);

  return (
    <Card className={`custom-card ${styles.wrapper}`}>
      <Row>
        {[allProductsCat, ...categories].slice(0, sliceNum()).map(cat => {
          return (
            <LinkContainer
              to={`/products/${cat.key}`}
              key={cat.key}
              className={styles.tile}
            >
              {renderIcon(cat)}
            </LinkContainer>
          );
        })}
      </Row>
      <Row>
        {[allProductsCat, ...categories]
          .slice(sliceNum(), sliceNum() * 2)
          .map(cat => {
            return (
              <LinkContainer
                to={`/products/${cat.key}`}
                key={cat.key}
                className={styles.tile}
              >
                {renderIcon(cat)}
              </LinkContainer>
            );
          })}
      </Row>
    </Card>
  );
};

CategoryTiles.propTypes = {
  categories: PropTypes.array
};

CategoryTiles.defaultProps = {
  categories: []
};

export default CategoryTiles;
