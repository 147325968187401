import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCut } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";

import styles from "./styles.module.scss";
import { couponDisplayValue, isEn, dateStr } from "../../lib";

const Coupon = ({ coupon }) => {
  const { t } = useTranslation();

  if (!coupon) {
    return null;
  }

  return (
    <div className={styles.couponWrapper}>
      <div className={styles.couponRow1}>
        <span>{isEn() ? coupon.name : coupon.cn}</span>
        <span>{couponDisplayValue(coupon)}</span>
      </div>
      {coupon.expiredAt && (
        <div className={styles.couponRow2}>{`exp: ${dateStr(
          coupon.expiredAt
        )}`}</div>
      )}
    </div>
  );
};

Coupon.defaultProps = {
  coupon: null
};

Coupon.propTypes = {
  coupon: PropTypes.object
};

export default Coupon;
