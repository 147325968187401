import React, { memo } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideMenu from "../Me/SideMenu";

const LayoutSideNav = memo(({ history, children }) => {
  return (
    <Container>
      <Row>
        <Col md={3}>
          <SideMenu history={history} />
        </Col>
        <Col md={9}>{children}</Col>
      </Row>
    </Container>
  );
});

export default LayoutSideNav;
