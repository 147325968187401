import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import ListGroup from "react-bootstrap/ListGroup";
import isEmpty from "lodash/isEmpty";

import styles from "./styles.module.scss";
import { moneyStr, totalPriceWithCoupon, couponCheckoutValue, isEn, totalPriceWithCoupons } from "../../lib";
import OrderItems from "../OrderItems";

const OrderSummary = ({
  orderItems,
  deliveryFee,
  subtotal,
  totalPrice,
  coupons
}) => {
  const { t } = useTranslation();

  const renderCouponTotal = () => {
    if (isEmpty(coupons)) {
      return null;
    }

    return coupons.map(coupon => {
      return (
        <ListGroup.Item key={`selected-coupon-${coupon.id}`}>
          <Row>
            <Col xs={6}>
              <span>{t("coupon")} x 1</span>
            </Col>
            <Col xs={6}>
              <span className={styles.price}>
                {couponCheckoutValue({ coupon, deliveryFee, subtotal })}
              </span>
            </Col>
          </Row>
        </ListGroup.Item>
      )
    });
  };

  return (
    <div className={styles.orderSummary}>
      <ListGroup variant="flush">
        <h4>{t(["checkout.step4.yourItems"])}</h4>
        <OrderItems orderItems={orderItems} />
        <Row>
          <Col md={{ span: 5, offset: 7 }}>
            <Card variant="paymentSummary">
              <Card.Header>
                <h4 className={styles.heading}>{t("totalAmount")}</h4>
              </Card.Header>
              <Card.Body>
                <div className={styles.totalPrice}>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <Row>
                        <Col xs={6}>
                          <span>{t("subtotal")}</span>
                        </Col>
                        <Col xs={6}>
                          <span className={styles.price}>
                            ${moneyStr(subtotal)}
                          </span>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col xs={6}>
                          <span>{t("deliveryFee")}</span>
                        </Col>
                        <Col xs={6}>
                          <span className={styles.price}>
                            ${moneyStr(deliveryFee)}
                          </span>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    {renderCouponTotal()}
                    <div className={styles.totalPriceWrapper}>
                      <ListGroup.Item>
                        <Row>
                          <Col xs={6}>
                            <span>
                              {t(["checkout.step4.total"])} (inc. GST)
                            </span>
                          </Col>
                          <Col xs={6}>
                            <span className={styles.price}>
                              $
                              {moneyStr(
                                totalPriceWithCoupons({
                                  coupons,
                                  deliveryFee,
                                  subtotal
                                })
                              )}
                            </span>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    </div>
                  </ListGroup>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </ListGroup>
    </div>
  );
};

OrderSummary.propTypes = {
  orderItems: PropTypes.array,
  coupons: PropTypes.array
};

OrderSummary.defaultProps = {
  orderItems: [],
  coupons: []
};

export default OrderSummary;
