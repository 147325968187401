import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const GenericLoader = ({ extraClassName, showOverlay, center }) => (
  <div
    className={`${extraClassName} ${styles.loader__wrapper} ${
      center ? styles.center : ""
    } ${showOverlay ? styles.showOverlay : ""}`}
  >
    <div className={styles.loader}>Loading...</div>
  </div>
);

GenericLoader.defaultProps = {
  extraClassName: "",
  showOverlay: false,
  center: false
};

GenericLoader.propTypes = {
  extraClassName: PropTypes.string,
  showOverlay: PropTypes.bool,
  center: PropTypes.bool
};

export default GenericLoader;
