import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faWeixin
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { get, capitalize } from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";

import styles from "./styles";
import wechatSrc from "../../pages/Contact/afm_wechat.jpeg";
import { allProductsCat, isEn } from "../../lib";

class Footer extends Component {
  render() {
    const { t, defaultCategories } = this.props;

    return (
      <Col className={styles.footerWrapper}>
        <Row>
          <Col>
            <h3>{t(["footer.info"])}</h3>
            <div className={styles.tileLinkWrapper}>
              <LinkContainer to="/about">
                <Nav.Link>{t("about.title")}</Nav.Link>
              </LinkContainer>
            </div>
            <div className={styles.tileLinkWrapper}>
              <LinkContainer to="/support">
                <Nav.Link>{t("support.title")}</Nav.Link>
              </LinkContainer>
            </div>
            <div className={styles.tileLinkWrapper}>
              <LinkContainer to="/checkout">
                <Nav.Link>{t("nav.checkout")}</Nav.Link>
              </LinkContainer>
            </div>
            <div className={styles.tileLinkWrapper}>
              <LinkContainer to="/how-to-order">
                <Nav.Link>{t("nav.howto")}</Nav.Link>
              </LinkContainer>
            </div>
            <div className={styles.tileLinkWrapper}>
              <LinkContainer to="/delivery">
                <Nav.Link>{t("nav.delivery")}</Nav.Link>
              </LinkContainer>
            </div>
            <div className={styles.tileLinkWrapper}>
              <LinkContainer to="/tnc">
                <Nav.Link>{t("nav.tnc")}</Nav.Link>
              </LinkContainer>
            </div>
            <div className={styles.tileLinkWrapper}>
              <a target="_blank" href="https://www.aus-fresh-meat.com.au/">
                {t("nav.wholesale")}
              </a>
            </div>
            <h3 className={styles.paymentHeader}>{t(["footer.payment"])}</h3>
            <Row className={styles.paymentMethods}>
              <Image
                className={styles.img}
                src="https://res.cloudinary.com/offbyonex/image/upload/q_auto,f_auto/afm/icons/icons8-visa-96"
                alt="visa"
              />
              <Image
                className={styles.img}
                src="https://res.cloudinary.com/offbyonex/image/upload/q_auto,f_auto/afm/icons/icons8-mastercard-96"
                alt="visa"
              />
              <Image
                className={styles.img}
                src="https://res.cloudinary.com/offbyonex/image/upload/q_auto,f_auto/afm/icons/icons8-wechat-96"
                alt="visa"
              />
            </Row>
          </Col>
          <Col>
            <h3>{t(["footer.products"])}</h3>
            {[allProductsCat, ...defaultCategories].map(cat => {
              return (
                <div
                  key={`footer-${cat.key}`}
                  className={`${styles.tileLinkWrapper} ${styles.products}`}
                >
                  <LinkContainer to={`/products/${cat.key}`}>
                    <Nav.Link>
                      {isEn() ? capitalize(cat.name) : cat.cn}
                    </Nav.Link>
                  </LinkContainer>
                </div>
              );
            })}
          </Col>
          <Col>
            <h3>{t(["footer.contact"])}</h3>
            <div className={styles.tile}>
              <FontAwesomeIcon size="2x" color="white" icon={faEnvelope} />
              <div>{t(["contact.email"])}</div>
              <div className={styles.email}>info@ausfreshmeat.com.au</div>
            </div>
            <div className={styles.tile}>
              <FontAwesomeIcon size="2x" color="white" icon={faPhone} />
              <div>{t(["contact.call"])}</div>
              03 9372 1288
            </div>
            <div className={styles.tile}>
              <div>{t(["contact.wechat"])}</div>
              <img src={wechatSrc} />
            </div>
          </Col>
        </Row>
        <Row className={styles.copyright}>{t(["footer.copyright"])}</Row>
      </Col>
    );
  }
}

const mapStateToProps = state => {
  return {
    defaultCategories: get(state.productReducer, "defaultCategories", [])
  };
};

export default withTranslation()(connect(mapStateToProps)(Footer));
