import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { LinkContainer } from "react-router-bootstrap";
import Image from "react-bootstrap/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./styles.module.scss";

const Banner = ({ src, alt, link, title, desc }) => {
  if (src) {
    return (
      <div className={styles.imageWrapper}>
        <LinkContainer to={link || "/#"}>
          <Image className={styles.img} src={src} alt={alt} />
        </LinkContainer>
      </div>
    );
  }
};

export default Banner;

Banner.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string
};
Banner.defaultPropTypes = {
  src: null,
  alt: null,
  link: null,
  title: null,
  desc: null
};
