export const SET_CART = "SET_CART";
export const SET_CART_TOTAL = "SET_CART_TOTAL";

export function setCart(payload) {
  return {
    type: SET_CART,
    payload
  };
}

export function setCartTotal(cartTotalOriginalPrice, cartTotalPrice) {
  return {
    type: SET_CART_TOTAL,
    payload: { cartTotalOriginalPrice, cartTotalPrice }
  };
}
