import React from "react";
import PropTypes from "prop-types";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { isEn } from "../../lib";
import styles from "./styles";

const QuantityBundles = ({ quantityBundles }) => {
  const { t } = useTranslation();

  if (_.isEmpty(quantityBundles)) {
    return null;
  }
  return (
    <div className={styles.qbsWrapper}>
      <div className={styles.qbsHeader}>
        <FontAwesomeIcon icon={faTag} />
        <div>{t("quantityBundles")}</div>
      </div>
      <div className={styles.qbsBody}>
        {quantityBundles.map(qb => {
          return <div key={`qb${qb.id}`}>{isEn() ? qb.name : qb.cn}</div>;
        })}
      </div>
    </div>
  );
};

export default QuantityBundles;

QuantityBundles.defaultProps = {
  quantityBundles: []
};

QuantityBundles.propTypes = {
  quantityBundles: PropTypes.array
};
