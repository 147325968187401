import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        nav: {
          menu: "Menu",
          contact: "Contact",
          checkout: "Checkout",
          me: "Me",
          login: "Login",
          signup: "Sign up",
          home: "Home",
          categories: "Categories",
          wholesale: "Wholesale",
          howto: "How to order",
          delivery: "Delivery",
          tnc: "Terms & conditions"
        },
        categories: {
          all: "View all",
          chicken: "chicken",
          pork: "pork",
          beef: "beef",
          lamb: "lamb",
          duck: "duck",
          seafood: "seafood",
          "pre-cooked": "pre-cooked",
          "drink-snacks": "drink & snacks",
          groceries: "groceries",
          vegetables: "veges",
          "hot-pot": "hot pot",
          other: "other"
        },
        checkoutNow: "Checkout",
        allProducts: "All products",
        quantityBundles: "Bundles",
        limitedStock: "Limited stock",
        outOfStock: "Out of stock",
        searchProducts: "Search products",
        searchAddress: "Search address",
        featuredTitle: "Weekly specials",
        newProducts: "New products",
        viewMore: "View more >>",
        topSellerTitle: "Top sellers",
        addToCart: "Add to cart",
        shoppingCart: "Shopping cart",
        itemsInCart: "items in cart",
        subtotal: "Subtotal",
        totalAmount: "Total amount",
        total: "Total",
        confirmPayment: "Confirm payment",
        clear: "clear",
        next: "Next",
        name: "Name",
        qty: "Qty",
        price: "Price",
        email: "Email",
        password: "Password",
        phone: "Phone",
        note: "Note",
        deliveryDate: "Delivery date",
        deliveryDateNote: "",
        deliveryFee: "Delivery fee",
        coupon: "Coupon",
        deliveryAddress: "Delivery address",
        orderItems: "Order items",
        payment: {
          title: "Choose a payment method",
          ccPay: "Credit/Debit card payment",
          wechatPay: "Wechat payment",
          wechatPayNote: "Please scan the image with Wechat",
          wechatPayOnSuccess:
            "We will send you email after successful payment, please check your email including junk mail folder. If you haven't receive our email 10 minutes after the payment, please contact us via email or phone.",
          wechatOrderButtonNote:
            "Please click below to find your order after your successful Wechat payment",
          checkOrder: "Check order"
        },
        profile: {
          title: "Profile",
          edit: "Edit"
        },
        dispatchedOrders: {
          title: "Dispatched orders"
        },
        placedOrders: {
          title: "Placed orders"
        },
        coupons: {
          title: "Coupons",
          validCoupons: "Valid coupons"
        },
        products: {
          productCode: "Product code",
          desc: "Description"
        },
        login: {
          title: "Login",
          email: "Email",
          password: "Password",
          noAccount: "No account? Sign up here",
          forgotPassword: "Forgot your password? Reset it here"
        },
        signup: {
          title: "Sign up",
          email: "Email",
          password: "Password",
          passwordConfirmation: "Confirm password",
          phone: "Phone",
          name: "Name"
        },
        forgotPassword: {
          title: "Recover your password",
          success: "Check your email for password reset instructions"
        },
        resetPassword: {
          title: "Reset password",
          success: "Your password has been reset!"
        },
        checkout: {
          title: "Checkout",
          step1: {
            heading: "1. How would you like to receive your groceries?",
            deliveringTo: "Delivering to:",
            recentAddresses: "Recent addresses:",
            newAddress: "New address:",
            note: "note: deliveries only available in Melbourne area"
          },
          step2: {
            heading: "2. When suits you?",
            title: "Select a day and time",
            morning: "Morning",
            afternoon: "Afternoon",
            evening: "Evening",
            allDay: "All day",
            selectAddressError: "Please select a delivery address first"
          },
          step3: {
            heading: "3. Add a note (optional)",
            title: "Add a note to your order"
          },
          step4: {
            heading: "4. Review your groceries",
            yourDetails: "Your details",
            shipping: "Shipping",
            yourItems: "Your items",
            note: "Note",
            total: "Total",
            pay: "Pay",
            selectCoupon: "Select coupon",
            yourCoupon: "Selected coupon"
          }
        },
        invoice: {
          orderNumber: "Order NO",
          title: "Payment successful!",
          subtitle: "The invoice has been sent to your email.",
          continueShopping: "Continue shopping",
          note: "Note"
        },
        contact: {
          title: "Contact us",
          email: "Send an email",
          call: "Give us a call",
          wechat: "Wechat assistant"
        },
        about: {
          title: "About us"
        },
        support: {
          title: "Support",
          deliveryArea: "Delivery area",
          returnPolicy: "Return policy"
        },
        me: {
          menu: {
            placedOrders: "Placed orders",
            dispatchedOrders: "Dispatched orders",
            profile: "Profile",
            coupons: "Coupons",
            logout: "Log out"
          }
        },
        errors: {
          minPurchase: "Minimum purchase is $20",
          closedOnHoliday:
            "Sorry, we are closed during public holidays. Please select a different date",
          negativeOrderTotal: "The order total must be greater than 0",
          overPurchaseLimit: "The products cannot be ordered due to purchase limit"
        },
        footer: {
          products: "Products",
          info: "Information",
          contact: "Contact us",
          copyright: "Copyright © 2020 - AusFreshMeat. All rights Reserved",
          payment: "Payment methods"
        }
      }
    },
    cn: {
      translation: {
        nav: {
          menu: "菜单",
          contact: "联系我们",
          checkout: "结账",
          me: "用户",
          login: "登陆",
          signup: "注册",
          home: "首页",
          categories: "产品分类",
          wholesale: "批发商铺",
          howto: "怎样购买",
          delivery: "发货信息",
          tnc: "条款"
        },
        categories: {
          all: "全部产品",
          chicken: "鸡肉",
          pork: "猪肉",
          beef: "牛肉",
          lamb: "羊肉",
          duck: "鸭肉",
          seafood: "海鲜",
          "pre-cooked": "半成品",
          "drink-snacks": "饮料和零食",
          groceries: "杂货",
          vegetables: "蔬菜",
          "hot-pot": "火锅",
          other: "其它"
        },
        checkoutNow: "立即结账",
        allProducts: "全部产品",
        quantityBundles: "捆绑销售",
        limitedStock: "低库存",
        outOfStock: "无库存",
        searchProducts: "产品搜索",
        searchAddress: "搜索地址",
        featuredTitle: "每周特价",
        newProducts: "新品上架",
        viewMore: "更多产品 >>",
        topSellerTitle: "热卖产品",
        addToCart: "添加产品",
        shoppingCart: "购物车",
        itemsInCart: "个产品",
        subtotal: "小计",
        totalAmount: "总计",
        total: "总计",
        confirmPayment: "确认付款",
        clear: "清空",
        next: "继续",
        name: "名字",
        qty: "数量",
        price: "价格",
        email: "邮件",
        phone: "电话",
        note: "备注",
        deliveryDate: "发货日期",
        deliveryDateNote: "",
        deliveryFee: "快递费用",
        coupon: "抵用券",
        deliveryAddress: "发货地址",
        orderItems: "货物清单",
        profile: {
          title: "用户信息",
          edit: "编辑"
        },
        coupons: {
          title: "抵用券",
          validCoupons: "可用抵用券"
        },
        products: {
          productCode: "产品代码",
          desc: "产品介绍"
        },
        login: {
          title: "用户登陆",
          email: "邮件",
          password: "密码",
          noAccount: "没有账户? 点击这里用户注册",
          forgotPassword: "忘记密码？ 点击这里重置密码"
        },
        signup: {
          title: "用户注册",
          email: "邮件",
          password: "密码",
          passwordConfirmation: "密码确认",
          phone: "电话",
          name: "名字"
        },
        forgotPassword: {
          title: "找回密码",
          success: "密码重置邮件已发，请查看邮箱"
        },
        resetPassword: {
          title: "重置密码",
          success: "密码重置成功！"
        },
        checkout: {
          title: "结账",
          step1: {
            heading: "1. 发货地址?",
            deliveringTo: "发送到:",
            recentAddresses: "最近地址:",
            newAddress: "新地址:",
            note: "备注: 只发货到墨尔本地区"
          },
          step2: {
            heading: "2. 发货时间?",
            title: "选择时间",
            morning: "上午",
            afternoon: "下午",
            evening: "晚间",
            allDay: "全天",
            selectAddressError: "请先选择发货地址"
          },
          step3: {
            heading: "3. 备注(可选项)",
            title: "加入订单备注"
          },
          step4: {
            heading: "4. 发货清单",
            yourDetails: "用户信息",
            shipping: "发货信息",
            yourItems: "货物清单",
            note: "订单备注",
            total: "总计",
            pay: "付款",
            selectCoupon: "选择抵用券",
            yourCoupon: "抵用券"
          }
        },
        invoice: {
          orderNumber: "订单号",
          title: "付款成功！",
          subtitle: "付款信息已发送, 请查收邮件",
          continueShopping: "继续购物",
          note: "备注"
        },
        me: {
          menu: {
            placedOrders: "未发货订单",
            dispatchedOrders: "已发货订单",
            profile: "用户信息",
            coupons: "抵用券",
            logout: "用户注销"
          }
        },
        dispatchedOrders: {
          title: "已发货订单"
        },
        placedOrders: {
          title: "未发货订单"
        },
        contact: {
          title: "联系我们",
          email: "邮件联系",
          call: "电话联系",
          wechat: "微信助手"
        },
        payment: {
          title: "选择付款方式",
          ccPay: "信用/借记卡支付",
          wechatPay: "微信支付",
          wechatPayNote: "请通过微信来扫码",
          wechatPayOnSuccess:
            "我们会在你成功付款后发确认邮件, 麻烦查收(有可能在广告邮箱). 如果没有收到邮件, 麻烦联系有邮件或者电话我们. 谢谢",
          wechatOrderButtonNote: "微信支付成功后, 请点击以下按钮来查询订单",
          checkOrder: "订单查询"
        },
        about: {
          title: "关于我们"
        },
        support: {
          title: "支持信息",
          deliveryArea: "发货地区",
          returnPolicy: "退货政策"
        },
        errors: {
          minPurchase: "$20 最低消费",
          closedOnHoliday: "抱歉, 我们节假日休息, 请选择不同的日期",
          negativeOrderTotal: "订单总价不能为负数",
          overPurchaseLimit: "产品限购无法购买"
        },
        footer: {
          products: "产品",
          info: "信息",
          contact: "联系我们",
          copyright: "© 2020 - AusFreshMeat 版权所有",
          payment: "支付方式"
        }
      }
    }
  },
  lng: "en",
  fallbackLng: "en",
  // ns: ["translations"],
  // defaultNS: "translations",
  interpolation: {
    escapeValue: true
  }
});

export default i18n;
