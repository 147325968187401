import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faPhone,
  faHome,
  faListUl,
  faStore,
  faInfo
} from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";
import NavDropdown from "react-bootstrap/NavDropdown";
import Col from "react-bootstrap/Col";

import styles from "./styles.module.scss";
import { ME } from "../../queries/me";
import { GET_PRODUCTS } from "../../queries/products";
import { GET_CATEGORIES } from "../../queries/categories";
import GenericSearch from "../GenericSearch";
import logoSrc from "./logo-text.png";
import { gqErrorMsg } from "../../lib";
import { setCart, setCartTotal } from "../CartFloating/actions";
import {
  setMe,
  setProducts,
  setClient,
  setCategories,
  setDefaultCategories
} from "./actions";
import AuthFloating from "../AuthFloating";
import LanToggle from "../LanToggle";
import history from "../../lib/history";
import { allProductsCat, isEn, defaultCategories } from "../../lib";

/**
 * @module Header
 */
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  componentDidMount = () => {
    this.props.setClient(this.props.client);
    this.queryProductsForSearch();
    this.queryMeData();
    this.fetchCategories();
  };

  queryProductsForSearch = () => {
    const { client } = this.props;
    client
      .query({ query: GET_PRODUCTS })
      .then(res => {
        this.props.setProducts(_.get(res, "data.products", []));
      })
      .catch(({ graphQLErrors }) => {
        // replace with redux
        this.setState({
          error: gqErrorMsg(graphQLErrors)
        });
      });
  };

  fetchCategories = () => {
    const { client, setCategories, setDefaultCategories } = this.props;
    client
      .query({ query: GET_CATEGORIES })
      .then(res => {
        const rval = _.get(res, "data.categories", []);
        setCategories(rval);
        setDefaultCategories(defaultCategories(rval));
      })
      .catch(({ graphQLErrors }) => {
        // replace with redux
        this.setState({
          error: gqErrorMsg(graphQLErrors)
        });
      });
  };

  queryMeData = () => {
    const { client } = this.props;
    client
      .query({ query: ME })
      .then(res => {
        const meData = _.get(res, "data.me", {});
        const loggedIn = !!_.get(meData, "id", null);
        const userData = _.omit(meData, [
          "cartTotalOriginalPrice",
          "cartTotalPrice",
          "cartItems"
        ]);

        if (loggedIn) {
          const cartItems = _.get(meData, "cartItems", []);
          const cartTotalOriginalPrice = _.get(
            meData,
            "cartTotalOriginalPrice",
            []
          );
          const cartTotalPrice = _.get(meData, "cartTotalPrice", []);

          this.props.setCart(cartItems);
          this.props.setCartTotal(cartTotalOriginalPrice, cartTotalPrice);
          this.props.setMe(userData);
        }
      })
      .catch(({ graphQLErrors }) => {
        this.setState({
          error: gqErrorMsg(graphQLErrors)
        });
      });
  };

  render() {
    const { error } = this.state;
    const { cartItems, products, defaultCategories, me, t } = this.props;
    const contactLink = (
      <LinkContainer to="/contact">
        <Nav.Link>
          <FontAwesomeIcon icon={faPhone} /> {t("nav.contact")}
        </Nav.Link>
      </LinkContainer>
    );
    const homeLink = (
      <LinkContainer to="/">
        <Nav.Link>
          <FontAwesomeIcon icon={faHome} /> {t("nav.home")}
        </Nav.Link>
      </LinkContainer>
    );
    const wholesaleLink = (
      <Nav.Link
        onClick={() =>
          window.open("https://www.aus-fresh-meat.com.au", "_blank")
        }
      >
        <FontAwesomeIcon icon={faStore} /> {t("nav.wholesale")}
      </Nav.Link>
    );
    const navDropdownTitle = (
      <>
        <FontAwesomeIcon icon={faListUl} /> {t("nav.categories")}
      </>
    );
    const categoryLink = (
      <NavDropdown title={navDropdownTitle}>
        {defaultCategories.map(cat => {
          return (
            <LinkContainer
              key={`nav-cat-${cat.id}`}
              to={`/products/${cat.key}`}
            >
              <Nav.Link className={styles.checkoutBtn}>
                {isEn() ? cat.name : cat.cn}
              </Nav.Link>
            </LinkContainer>
          );
        })}
        <NavDropdown.Divider />
        <LinkContainer to={`/products`}>
          <Nav.Link className={styles.checkoutBtn}>
            {isEn() ? allProductsCat.name : allProductsCat.cn}
          </Nav.Link>
        </LinkContainer>
      </NavDropdown>
    );
    const howToTitle = (
      <>
        <FontAwesomeIcon icon={faInfo} /> {t("nav.howto")}
      </>
    );
    const howToLink = (
      <NavDropdown title={howToTitle}>
        <LinkContainer to="/how-to-order">
          <Nav.Link className={styles.checkoutBtn}>{t(`nav.howto`)}</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/delivery">
          <Nav.Link className={styles.checkoutBtn}>
            {t(`nav.delivery`)}
          </Nav.Link>
        </LinkContainer>
        <LinkContainer to="/tnc">
          <Nav.Link className={styles.checkoutBtn}>{t(`nav.tnc`)}</Nav.Link>
        </LinkContainer>
      </NavDropdown>
    );

    return (
      <Navbar className={styles.navbar} collapseOnSelect expand="lg">
        <div className="custom-nav-row w-100 align-items-center">
          <Navbar.Toggle
            className={styles.navbarToggle}
            aria-controls="responsive-navbar-nav"
          >
            <span>{t("nav.menu")}</span>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav" className={styles.navbar}>
            <Nav className={`${styles.primaryNav}`}>
              <Col md="auto">
                <LinkContainer to="/">
                  <Nav.Link>
                    <img className={styles.logo} src={logoSrc} />
                  </Nav.Link>
                </LinkContainer>
              </Col>
              <Col md="5">
                <GenericSearch
                  error={error}
                  data={products}
                  handleSearch={selected =>
                    history.push(`/product/${_.get(selected, "[0].id")}`)
                  }
                />
              </Col>
              <Col md="auto">
                <LinkContainer to={me.id ? "/checkout" : "/login"}>
                  <Nav.Link className={styles.checkoutBtn}>
                    <FontAwesomeIcon size="lg" icon={faShoppingCart} />{" "}
                    {t("nav.checkout")}{" "}
                    {cartItems.length > 0 && (
                      <Badge variant="secondary">{cartItems.length}</Badge>
                    )}
                  </Nav.Link>
                </LinkContainer>
              </Col>
            </Nav>
            <Nav className={`${styles.secondaryNav}`}>
              <Col md="auto">{homeLink}</Col>
              <Col md="auto">{categoryLink}</Col>
              {/*<Col md="auto">{howToLink}</Col>*/}
              <Col md="auto">{contactLink}</Col>
              <Col md="auto">{wholesaleLink}</Col>
            </Nav>
          </Navbar.Collapse>
          <div className={styles.floatingWrapper}>
            <AuthFloating me={me} />
            <LanToggle />
          </div>
        </div>
      </Navbar>
    );
  }
}

Header.defaultProps = {
  defaultPropGoesHere: "default prop",
  cartItems: [],
  me: {},
  client: {}
};

Header.propTypes = {
  defaultPropGoesHere: PropTypes.string,
  cartItems: PropTypes.array,
  me: PropTypes.object,
  client: PropTypes.object
};

const mapStateToProps = state => {
  return {
    cartItems: _.get(state.cartReducer, "cartItems", []),
    me: _.get(state.meReducer, "me", {}),
    products: _.get(state.productReducer, "products", []),
    defaultCategories: _.get(state.productReducer, "defaultCategories", [])
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCart,
      setMe,
      setCartTotal,
      setProducts,
      setCategories,
      setDefaultCategories,
      setClient
    },
    dispatch
  );

// To promote a component to a container (smart component) - it needs
// to know about this new dispatch method. Make it available
// as a prop.
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Header)
);
