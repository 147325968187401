import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";

import { isEn } from "../../lib";
import styles from "./styles";
import En from "./en";
import Cn from "./cn";

const About = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Card>
        <Card.Body className={styles.aboutWrapper}>
          <Row>
            <Col md="auto" >
              { isEn() ? <En /> : <Cn /> }
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default About;
