import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import CartFloating from "../../../components/CartFloating";
import ProductList from "../../../components/ProductList";
import { filterProducts, isEn, allProductsCat } from "../../../lib";
import CategoryTiles from "../../../components/CategoryTiles";

class List extends Component {
  render() {
    const {
      client,
      products,
      match,
      categories,
      defaultCategories
    } = this.props;
    const filter = _.get(match, "params.filter", null);
    const filteredProducts = filterProducts({ products, filter });
    const category = _.isEmpty(filter)
      ? allProductsCat
      : categories.find(c => c.key === filter);

    if (filteredProducts.length === 0) {
      return (
        <>
          <CategoryTiles categories={defaultCategories} />
        </>
      );
    }

    return (
      <>
        <CategoryTiles categories={defaultCategories} />
        <ProductList
          client={client}
          products={filteredProducts}
          title={isEn() ? category?.name : category?.cn}
          display="PRODUCT"
          direction="TILES"
          infiniteScroll={true}
        />
        <CartFloating client={client} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: _.get(state.productReducer, "products", []),
    categories: _.get(state.productReducer, "categories", []),
    defaultCategories: _.get(state.productReducer, "defaultCategories", [])
  };
};

export default withTranslation()(connect(mapStateToProps)(List));
