import gql from "graphql-tag";

export const ADD_TO_CART = gql`
  mutation addToCart($productId: ID!, $quantity: Int!, $notes: String) {
    addToCart(
      input: { productId: $productId, quantity: $quantity, notes: $notes }
    ) {
      cartItems {
        name
        cn
        variant
        price
        discount
        totalPrice
        quantity
        notes
        id
        orderId
        productId
        images
        quantityBundleName
        quantityBundleCn
        quantityBundleQuantity
        quantityBundlePercentage
      }
      cartTotalOriginalPrice
      cartTotalPrice
    }
  }
`;

export const UPDATE_CART = gql`
  mutation updateCart($orderItemId: Int!, $quantity: Int, $notes: String) {
    updateCart(
      input: { orderItemId: $orderItemId, quantity: $quantity, notes: $notes }
    ) {
      cartItems {
        id
        name
        cn
        variant
        price
        discount
        totalPrice
        quantity
        notes
        orderId
        productId
        quantity
        totalPrice
        images
        quantityBundleName
        quantityBundleCn
        quantityBundleQuantity
        quantityBundlePercentage
      }
      cartTotalOriginalPrice
      cartTotalPrice
    }
  }
`;
