import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";

import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";

import LayoutSideNav from "../../LayoutSideNav";
import EditDetails from "./EditDetails";
import EditRecentAddresses from "./EditRecentAddresses";
import { deleteAddress } from "./actions";
import { DELETE_ADDRESS } from "../../../mutations/users";
import { gqErrorMsg } from "../../../lib";

import styles from "./styles.module.scss";
import Alert from "react-bootstrap/Alert";

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      editingProfile: false,
      editingAddresses: false,
      error: null
    };
  }

  handleEditingProfile = () =>
    this.setState({ editingProfile: !this.state.editingProfile });
  handleEditingAddress = () =>
    this.setState({ editingAddresses: !this.state.editingAddresses });

  handleDeleteAddress = id => {
    this.props.client
      .mutate({
        mutation: DELETE_ADDRESS,
        variables: { id }
      })
      .then(() => {
        this.props.deleteAddress(id);
        this.handleEditingAddress();
      })
      .catch(({ graphQLErrors }) => {
        // replace with redux
        this.setState({
          error: gqErrorMsg(graphQLErrors)
        });
      });
  };

  render() {
    const { me, t, history } = this.props;
    const { editingProfile, editingAddresses, error } = this.state;
    const addresses = me.addresses || [];

    return (
      <LayoutSideNav history={history}>
        <EditDetails
          editing={editingProfile}
          userId={me.id}
          name={me.name}
          email={me.email}
          phone={me.phone}
          handleEditingState={this.handleEditingProfile}
        />

        {!editingProfile && (
          <div className={styles.editWrapper}>
            <Button variant="light" onClick={this.handleEditingProfile}>
              <FontAwesomeIcon icon={faUserEdit} className="mr-2" />
              {t(["profile.edit"])}
            </Button>
          </div>
        )}
        <hr />
        <h5>{t(["checkout.step1.recentAddresses"])}</h5>
        <EditRecentAddresses
          editing={editingAddresses}
          addresses={addresses}
          handleEditingState={this.handleEditingAddress}
          handleDeleteAddress={this.handleDeleteAddress}
        />
        {!editingAddresses && (
          <div className={styles.editWrapper}>
            <Button variant="light" onClick={this.handleEditingAddress}>
              <FontAwesomeIcon icon={faUserEdit} className="mr-2" />
              {t(["profile.edit"])}
            </Button>
          </div>
        )}
        {error && (
          <Alert variant="danger">{error}</Alert>
        )}
      </LayoutSideNav>
    );
  }
}

const mapStateToProps = state => {
  return {
    me: _.get(state.meReducer, "me", {})
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteAddress
    },
    dispatch
  );

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
