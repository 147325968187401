import React, { Component } from "react";
import PropTypes from "prop-types";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import EditActionButtons from "../EditActionButtons";
import styles from "./styles.module.scss";

const EditRecentAddresses = ({ editing,  addresses,  handleEditingState,  handleDeleteAddress}) => (
  <div>
    {addresses.map(addr => {
      return (
        <div key={`addr-${addr.id}`} className={`${styles.editAddressWrapper} ${editing && styles.editing}`}>
          <InputGroup key={addr.canonicalAddressId} className="mb-3">
            {editing && (
              <InputGroup.Prepend>
                <Button onClick={() => handleDeleteAddress(addr.id)}>
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </InputGroup.Prepend>
            )}
            <InputGroup.Text>{addr.fullAddress}</InputGroup.Text>
          </InputGroup>
        </div>
      );
    })}
    {editing && (
      <EditActionButtons handleEditingState={handleEditingState} />
    )}
  </div>
);

EditRecentAddresses.propTypes = {
  addresses: PropTypes.array
};

EditRecentAddresses.defaultProps = {
  addresses: []
};

export default EditRecentAddresses;
