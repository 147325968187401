import gql from "graphql-tag";

export const CHECKOUT = gql`
  mutation checkout(
    $partial: Boolean
    $deliveryTimeId: Int!
    $deliveryDate: String!
    $canonicalAddressId: String!
    $fullAddress: String!
    $couponIds: [ID!]
    $note: String
    $locationId: ID!
  ) {
    checkout(
      input: {
        partial: $partial
        deliveryTimeId: $deliveryTimeId
        deliveryDate: $deliveryDate
        canonicalAddressId: $canonicalAddressId
        fullAddress: $fullAddress
        couponIds: $couponIds
        note: $note
        locationId: $locationId
      }
    ) {
      checkout {
        qrCode
        paymentIntent {
          clientSecret
          id
        }
        order {
          id
          orderNumber
          totalPrice
          status
          paidAt
          deliveryPrice
          deliveryTimeFrom
          deliveryTimeTo
          deliveryTimeId
          deliveryAddress
          couponPrice
          note
          orderItems {
            id
            notes
            name
            cn
            variant
            quantity
            price
            discount
            totalPrice
            quantityBundleName
            quantityBundleCn
            quantityBundleQuantity
            quantityBundlePercentage
          }
        }
      }
      outOfStock {
        name
        variant
        price
        discount
        totalPrice
        quantity
        notes
        id
        orderId
      }
    }
  }
`;
