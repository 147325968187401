import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import EditActionButtons from "../EditActionButtons";

import { UPDATE_USER } from "../../../../mutations/users";
import { setMe } from "../../../Header/actions";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const EditDetails = ({
  handleEditingState,
  userId,
  name,
  phone,
  email,
  editing
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [updateUser, { error }] = useMutation(UPDATE_USER, {
    onCompleted(data) {
      dispatch(setMe(data.updateUser.user));
      handleEditingState();
    }
  });

  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      name,
      phone,
      email
    }
  });

  const onSubmit = async data => {
    await updateUser({
      variables: {
        id: userId,
        email: data.email,
        name: data.name,
        phone: data.phone
      }
    });
  };

  return (
    <Row>
      <Col>
        <form method="POST" onSubmit={handleSubmit(onSubmit)} name="profile">
          <Form.Group controlId="name">
            <Form.Label>{t(["name"])}</Form.Label>
            {editing ? (
              <Form.Control
                autoFocus
                type="name"
                name="name"
                disabled={!editing}
                ref={register({ required: true })}
              />
            ) : (
              <Form.Control readOnly defaultValue={name} />
            )}
            {errors.name && <p className="form-error">This is required</p>}
          </Form.Group>
          <Form.Group controlId="phone">
            <Form.Label>{t(["phone"])}</Form.Label>
            {editing ? (
              <Form.Control
                autoFocus
                type="number"
                name="phone"
                disabled={!editing}
                ref={register({ required: true })}
              />
            ) : (
              <Form.Control readOnly defaultValue={phone} />
            )}
            {errors.phone && <p className="form-error">This is required</p>}
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>{t(["email"])}</Form.Label>
            {editing ? (
              <Form.Control
                autoFocus
                type="email"
                name="email"
                disabled={!editing}
                ref={register({ required: true })}
              />
            ) : (
              <Form.Control readOnly defaultValue={email} />
            )}
            {errors.email && <p className="form-error">This is required</p>}
          </Form.Group>
          {editing && (
            <EditActionButtons handleEditingState={handleEditingState} />
          )}
        </form>
        {error && <Alert variant="danger">{error}</Alert>}
      </Col>
    </Row>
  );
};

EditDetails.propTypes = {
  editing: PropTypes.bool,
  name: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string
};

EditDetails.defaultProps = {
  editing: false
};

export default EditDetails;
