import { combineReducers } from "redux";
import appReducer from "./appReducer";
import meReducer from "./meReducer";
import cartReducer from "./cartReducer";
import productReducer from "./productReducer";
import checkoutReducer from "./checkoutReducer";

// a function that returns a piece of the application state
// because application can have many different pieces of state == many reducers
const rootReducer = combineReducers({
  appReducer,
  meReducer,
  cartReducer,
  productReducer,
  checkoutReducer
});

export default rootReducer;
