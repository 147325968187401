import gql from "graphql-tag";

export const ME = gql`
  query me {
    me {
      id
      name
      email
      phone
      cartTotalOriginalPrice
      cartTotalPrice
      cartItems {
        name
        cn
        variant
        price
        discount
        totalPrice
        quantity
        notes
        id
        orderId
        productId
        images
        quantityBundleName
        quantityBundleCn
        quantityBundleQuantity
        quantityBundlePercentage
      }
      addresses {
        id
        canonicalAddressId
        fullAddress
        addressableId
        addressableType
      }
      activeFeatures
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logoutUser(input: {}) {
      clientMutationId
    }
  }
`;

export const GET_ORDERS = gql`
  query fetchOrders(
    $cursor: String
    $page: Int
    $statuses: [String!]
    $startDate: String
    $endDate: String
  ) {
    orders(
      after: $cursor
      page: $page
      statuses: $statuses
      startDate: $startDate
      endDate: $endDate
    ) {
      totalCount
      totalPages
      edges {
        node {
          id
        }
        cursor
      }
      nodes {
        id
        orderNumber
        totalPrice
        deliveryAddress
        deliveryTimeFrom
        deliveryTimeTo
        deliveryTimeId
        deliveryPrice
        couponPrice
        orderItems {
          id
          notes
          name
          cn
          variant
          quantity
          price
          totalPrice
          discount
          quantityUnit
          quantityBundleName
          quantityBundleCn
          quantityBundleQuantity
          quantityBundlePercentage
        }
      }
    }
  }
`;

export const GET_SINGLE_ORDER = gql`
  query fetchOrders($cursor: String, $page: Int, $orderNumber: String) {
    orders(after: $cursor, page: $page, orderNumber: $orderNumber) {
      totalCount
      totalPages
      edges {
        node {
          id
        }
        cursor
      }
      nodes {
        id
        orderNumber
        totalPrice
        deliveryAddress
        deliveryTimeFrom
        deliveryTimeTo
        deliveryTimeId
        deliveryPrice
        status
        paymentType
        orderItems {
          id
          notes
          name
          cn
          variant
          quantity
          price
          totalPrice
          discount
          quantityUnit
          quantityBundleName
          quantityBundleCn
          quantityBundleQuantity
          quantityBundlePercentage
        }
      }
    }
  }
`;

export const GET_TAGGABLE_ORDER = gql`
  query fetchTaggableOrder($deliveryAddress: String, $deliveryDate: String) {
    taggableOrder(
      deliveryAddress: $deliveryAddress
      deliveryDate: $deliveryDate
    ) {
      id
    }
  }
`;
