import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Typeahead } from "react-bootstrap-typeahead";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import history from "../../lib/history";
import { isEn } from "../../lib";

const GenericSearch = ({ id, data, error, loading, handleSearch }) => {
  const { t } = useTranslation();
  const [selected, handleOnChange] = useState(null);
  const onSubmit = event => {
    event.preventDefault();
    handleSearch(selected);
  };
  return (
    <Form
      method="POST"
      className={`${styles.searchWrapper}`}
      onSubmit={e => onSubmit(e)}
    >
      <InputGroup>
        <Typeahead
          id={id}
          isLoading={loading}
          labelKey={isEn() ? "name" : "cn"}
          options={data}
          placeholder={t("searchProducts")}
          onChange={v => handleOnChange(v)}
        />
        <InputGroup.Append>
          <Button variant="outline-info" type="submit">
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroup.Append>
      </InputGroup>
      {error && <p>{error}</p>}
    </Form>
  );
};

GenericSearch.defaultProps = {
  id: "typehead-id",
  data: [],
  error: null,
  loading: false,
  handleSearch: f => f
};

GenericSearch.propTypes = {
  id: PropTypes.string,
  data: PropTypes.array,
  error: PropTypes.object,
  loading: PropTypes.bool,
  handleSearch: PropTypes.func
};

export default GenericSearch;
