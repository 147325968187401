import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.scss";
import DiscountPrice from "../DiscountPrice";

const DeliveryTimeItem = ({
  id,
  from,
  to,
  note,
  price,
  totalPrice,
  discount,
  status,
  selectedTimeId,
  handleSetDeliveryTime,
  cartTotalPrice,
  taggableOrder
}) => {
  const timeRange = `${from} to ${to}`;
  const revisedDiscount =
    cartTotalPrice > 12000 || taggableOrder ? 100 : discount;
  const revisedTotal = cartTotalPrice > 12000 || taggableOrder ? 0 : totalPrice;

  return (
    <div
      className={`${styles.btnWrapper} ${status === "closed" && styles.closed}`}
    >
      <button
        className={`${styles.btn} ${selectedTimeId === id && styles.selected}`}
        onClick={() =>
          handleSetDeliveryTime(id, price, totalPrice, discount, timeRange)
        }
        disabled={status === "closed"}
      >
        <div className={styles.timeWrapper}>
          <span className={styles.timeRange}>
            {from}
            <FontAwesomeIcon size="lg" icon={faLongArrowAltRight} />
            {to}
          </span>
          <span className={styles.note}>{note}</span>
        </div>
        <div className={styles.price}>
          <DiscountPrice
            originalPrice={price}
            discount={revisedDiscount}
            finalPrice={revisedTotal}
          />
        </div>
      </button>
    </div>
  );
};

DeliveryTimeItem.propTypes = {
  id: PropTypes.number,
  from: PropTypes.string,
  to: PropTypes.string,
  note: PropTypes.string,
  price: PropTypes.number,
  status: PropTypes.string
};

export default DeliveryTimeItem;
