import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styles from "./styles";

const DateRangePicker = ({
  search,
  startDate,
  endDate,
  setStartDate,
  setEndDate
}) => {
  const onChangeStartDate = date => setStartDate(date);
  const onChangeEndDate = date => setEndDate(date);

  return (
    <Row className={styles.flexRow}>
      <Col className={styles.dateWrapper}>
        <span>From:</span>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={startDate}
          onChange={onChangeStartDate}
        />
      </Col>
      <Col className={styles.dateWrapper}>
        <span>To:</span>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={endDate}
          onChange={onChangeEndDate}
        />
      </Col>
      <Col>
        <Button
          onClick={() => search({ startDate, endDate })}
          variant="gradient"
        >
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </Col>
    </Row>
  );
};

export default DateRangePicker;
