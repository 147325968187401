import { SET_ME } from "../components/Header/actions";
import { DELETE_ADDRESS } from "../components/Me/Profile/actions";

const DEFAULT_APP_SCHEMA = {
  me: {
    addresses: []
  }
};

export default function(state = DEFAULT_APP_SCHEMA, action) {
  switch (action.type) {
    case SET_ME:
      return {
        ...state,
        me: action.payload
      };
    case DELETE_ADDRESS:
      const addressId = action.payload;
      return {
        ...state,
        me: {
          ...state.me,
          addresses: state.me.addresses.filter(
            address => address.id !== addressId
          )
        }
      };
    default:
      return state;
  }
}
