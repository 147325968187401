export const SET_DELIVERY_TIME_ID = "SET_DELIVERY_TIME";
export const SET_DELIVERY_DATE = "SET_DELIVERY_DATE";
export const SET_DELIVERY_TIME_RANGE = "SET_DELIVERY_TIME_RANGE";
export const SET_DELIVERY_COST = "SET_DELIVERY_COST";
export const SET_CANONICAL_ADDRESS_ID = "SET_CANONICAL_ADDRESS_ID";
export const SET_FULL_ADDRESS = "SET_FULL_ADDRESS";
export const SET_PAYMENT_INTENT = "SET_PAYMENT_INTENT";
export const SET_PENDING_ORDER = "SET_PENDING_ORDER";
export const SET_ADDRESS_API_META_DATA = "SET_ADDRESS_API_META_DATA";
export const SET_QR_CODE = "SET_QR_CODE";
export const CLEAR_CHECKOUT_META = "CLEAR_CHECKOUT_META";
export const CLEAR_CHECKOUT = "CLEAR_CHECKOUT";
export const SET_COUPONS = "SET_COUPONS";
export const SET_NOTE = "SET_NOTE";
export const SET_DELIVERY_TIMES_PER_LOCATION =
  "SET_DELIVERY_TIMES_PER_LOCATION";
export const SET_LOCATION = "SET_LOCATION";
export const SET_AVAILABLE_COUPONS = "SET_AVAILABLE_COUPONS";
export const SET_PAID_ORDER = "SET_PAID_ORDER";

export function setDeliveryTimeId(payload) {
  return {
    type: SET_DELIVERY_TIME_ID,
    payload
  };
}

export function setDeliveryDate(payload) {
  return {
    type: SET_DELIVERY_DATE,
    payload
  };
}

export function setDeliveryTimeRange(payload) {
  return {
    type: SET_DELIVERY_TIME_RANGE,
    payload
  };
}

export function setDeliveryCost(price = 0, totalPrice = 0, discount = 0) {
  return {
    type: SET_DELIVERY_COST,
    payload: {
      price: price,
      discount: discount,
      totalPrice: totalPrice
    }
  };
}

export function setCanonicalAddressId(payload) {
  return {
    type: SET_CANONICAL_ADDRESS_ID,
    payload
  };
}

export function setFullAddress(payload) {
  return {
    type: SET_FULL_ADDRESS,
    payload
  };
}

export function setPaymentIntent(payload) {
  return {
    type: SET_PAYMENT_INTENT,
    payload
  };
}

export function setQRCode(payload) {
  return {
    type: SET_QR_CODE,
    payload
  };
}

export function setPendingOrder(payload) {
  return {
    type: SET_PENDING_ORDER,
    payload
  };
}

export function setaddressAPIMetaData(payload) {
  return {
    type: SET_ADDRESS_API_META_DATA,
    payload
  };
}

export function clearCheckoutMeta(payload) {
  return {
    type: CLEAR_CHECKOUT_META,
    payload
  };
}

export function clearCheckout(payload) {
  return {
    type: CLEAR_CHECKOUT,
    payload
  };
}

export function setCoupons(payload) {
  return {
    type: SET_COUPONS,
    payload
  };
}

export function setNote(payload) {
  return {
    type: SET_NOTE,
    payload
  };
}

export function setDeliveryTimesPerLocation(payload) {
  return {
    type: SET_DELIVERY_TIMES_PER_LOCATION,
    payload
  };
}

export function setLocation(payload) {
  return {
    type: SET_LOCATION,
    payload
  };
}

export function setAvailableCoupons(payload) {
  return {
    type: SET_AVAILABLE_COUPONS,
    payload
  };
}

export function setPaidOrder(payload) {
  return {
    type: SET_PAID_ORDER,
    payload
  };
}
