import React from "react";
import PropTypes from "prop-types";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";
import { moneyStr } from "../../lib";
import DiscountPrice from "../DiscountPrice";

const DeliverySummary = ({
  name,
  email,
  phone,
  deliveryDate,
  deliveryTimeRange,
  deliveryOriginalPrice,
  deliveryDiscount,
  deliveryFinalPrice,
  deliveryAddress
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.deliverySummaryWrapper}>
      <ListGroup variant="flush">
        <h4>{t(["checkout.step4.yourDetails"])}</h4>
        <ListGroup.Item>
          <Row>
            <Col xs={12} md={2}>
              <span>{t("name")}</span>
            </Col>
            <Col xs={10}>
              <p>{name}</p>
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row>
            <Col xs={12} md={2}>
              <span>{t("email")}</span>
            </Col>
            <Col xs={10}>
              <p>{email}</p>
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row>
            <Col xs={12} md={2}>
              <span>{t("phone")}</span>
            </Col>
            <Col xs={10}>
              <p>{phone}</p>
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
      <ListGroup variant="flush">
        <h4>{t(["checkout.step4.shipping"])}</h4>
        <ListGroup.Item>
          <Row>
            <Col xs={12} md={2}>
              <span>{t("deliveryDate")}</span>
            </Col>
            <Col xs={10}>
              <p>
                {deliveryDate}{" "}
                <span className={styles.timeRange}>
                  {deliveryTimeRange && `(${deliveryTimeRange})`}
                </span>
              </p>
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row>
            <Col xs={12} md={2}>
              <span>{t("deliveryFee")}</span>
            </Col>
            <Col xs={10}>
              <div className={styles.discountWrapper}>
                <DiscountPrice
                  originalPrice={deliveryOriginalPrice}
                  discount={deliveryDiscount}
                  finalPrice={deliveryFinalPrice}
                />
              </div>
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row>
            <Col xs={12} md={2}>
              <span>{t("deliveryAddress")}</span>
            </Col>
            <Col xs={10}>
              <p>{deliveryAddress}</p>
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

DeliverySummary.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  deliveryDate: PropTypes.string,
  deliveryTimeRange: PropTypes.string,
  deliveryFee: PropTypes.number,
  deliveryAddress: PropTypes.string
};

DeliverySummary.defaultProps = {
  name: "",
  email: "",
  phone: "",
  deliveryDate: "",
  deliveryTimeRange: "",
  deliveryFee: 0,
  deliveryAddress: ""
};

export default DeliverySummary;
