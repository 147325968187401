import React from "react";
import styles from "./styles";

const Ribbon = ({ text, alt }) =>
  text && (
    <div className={alt ? styles.ribbonAlt : styles.ribbon}>
      <span>{text}</span>
    </div>
  );

export default Ribbon;
