import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";

import styles from "./styles.module.scss";

const GenericAlert = ({
  variant,
  title,
  children,
  closeText,
  closeCallback,
  icon
}) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    closeCallback();
  };

  return (
    <>
      <Modal
        variant={variant}
        show={show}
        onHide={handleClose}
        centered
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {icon}
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        {closeText && (
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              {closeText}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

GenericAlert.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  closeText: PropTypes.string
};

GenericAlert.defaultProps = {
  variant: "alert",
  title: "Oops",
  icon: <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />,
  children: null
};

export default GenericAlert;
