import { SET_CLIENT } from "../components/Header/actions";

const DEFAULT_APP_SCHEMA = {
  client: {}
};

export default function(state = DEFAULT_APP_SCHEMA, action) {
  switch (action.type) {
    case SET_CLIENT:
      return {
        ...state,
        client: action.payload
      };
    default:
      return state;
  }
}
