import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.scss";
import CustomArrowButton from "../CustomArrowButton";
import { isEn } from "../../lib";

const GenericCarousel = ({ slides }) => {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    setDirection(e.direction);
  };

  return (
    <div className={styles.carouselWrapper}>
      <Carousel
        nextIcon={<CustomArrowButton left={false} />}
        prevIcon={<CustomArrowButton left={true} />}
        interval={6000}
        activeIndex={index}
        direction={direction}
        onSelect={handleSelect}
      >
        {GenericCarousel.buildItems(slides)}
      </Carousel>
    </div>
  );
};

GenericCarousel.propTypes = {
  slides: PropTypes.array
};

GenericCarousel.defaultProps = {
  slides: []
};

GenericCarousel.isExternalLink = urlString => {
  const httpPattern = /^https?:\/\//i;
  return httpPattern.test(urlString);
};

GenericCarousel.isPdfLink = urlString => {
  const httpPattern = /\.pdf/i;
  return httpPattern.test(urlString);
};

GenericCarousel.buildItems = items =>
  items.map((item, index) => {
    if (
      GenericCarousel.isExternalLink(item.cta?.link) ||
      GenericCarousel.isPdfLink(item.cta?.link)
    ) {
      return (
        <Carousel.Item
          onClick={() => window.location.assign(item.cta.link)}
          key={`slide-${index}-${btoa(item.src)}`}
        >
          {item.src && (
            <div className={styles.imageWrapper}>
              <Image className={styles.img} src={item.src} alt={item.alt} />
            </div>
          )}
          {item.caption && (
            <Carousel.Caption>
              <h3>{item.caption.title}</h3>
              <p>{item.caption.summary}</p>
            </Carousel.Caption>
          )}
        </Carousel.Item>
      );
    }
    return (
      <LinkContainer key={`item${index}`} to={item.cta?.link}>
        <Carousel.Item key={`slide-${index}-${btoa(item.src)}`}>
          {item.src && (
            <div className={styles.imageWrapper}>
              <Image className={styles.img} src={item.src} alt={item.alt} />
            </div>
          )}
          {item.caption && (
            <Carousel.Caption>
              <h3>{item.caption.title}</h3>
              <p>{item.caption.summary}</p>
            </Carousel.Caption>
          )}
        </Carousel.Item>
      </LinkContainer>
    );
  });

GenericCarousel.buildButton = item =>
  GenericCarousel.isExternalLink(item.cta.link) ? (
    <Button href={item.cta?.link}>
      <span>{item.cta?.label}</span>
      <FontAwesomeIcon icon={faChevronRight} />
    </Button>
  ) : (
    item.cta?.label && (
      <LinkContainer to={item.cta?.link}>
        <Button>
          <span>{item.cta?.label}</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </LinkContainer>
    )
  );

export default GenericCarousel;
