import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import { RESET_PASSWORD } from "../../mutations/users";
import { gqErrorMsg } from "../../lib";

const ResetPassword = ({ token }) => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(null);
  const { register, errors, handleSubmit, setError } = useForm();
  const [resetPassword, { error }] = useMutation(RESET_PASSWORD, {
    onCompleted(data) {
      setSuccess(t(["resetPassword.success"]));
      window.location.assign("/");
    }
  });

  const onSubmit = async data => {
    if (data.passwordConfirmation != data.password) {
      return setError(
        "passwordConfirmation",
        "passwordMismatch",
        "Password confirmation doesn't match"
      );
    }
    await resetPassword({
      variables: {
        token,
        password: data.password,
        passwordConfirmation: data.passwordConfirmation
      }
    });
  };

  return (
    <React.Fragment>
      <form method="POST" onSubmit={handleSubmit(onSubmit)} name="email">
        <Form.Group controlId="password">
          <Form.Label>{t(["signup.password"])}</Form.Label>
          <Form.Control
            type="password"
            name="password"
            ref={register({ required: true })}
          />
          {errors.password && <p className="form-error">This is required</p>}
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>{t(["signup.passwordConfirmation"])}</Form.Label>
          <Form.Control
            type="password"
            name="passwordConfirmation"
            ref={register({ required: true })}
          />
          {_.get(errors, "passwordConfirmation.message")}
        </Form.Group>
        <Button block type="submit">
          {t(["resetPassword.title"])}
        </Button>
        {success && <Alert variant="success">{success}</Alert>}
        {error && <Alert variant="danger">{gqErrorMsg(error)}</Alert>}
      </form>
    </React.Fragment>
  );
};

export default ResetPassword;
