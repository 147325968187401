import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faTimes,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

import { setCart, setCartTotal } from "./actions";
import { moneyStr, isEn } from "../../lib";
import DiscountPrice from "../DiscountPrice";
import _ from "lodash";
import history from "../../lib/history";
import AddToCart from "../AddToCart";
import styles from "./styles.module.scss";

class CartFloating extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cartOpen: false
    };
  }

  componentDidUpdate() {
    const { cartOpen } = this.state;
    cartOpen
      ? document.body.classList.add("cart-open")
      : document.body.classList.remove("cart-open");
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.body.classList.remove("cart-open");
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  /**
   * Set the wrapper ref
   */
  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      document.body.classList.remove("cart-open");
      this.setState({ cartOpen: false });
    }
  };

  handleCartTrigger = () =>
    this.setState(prevState => ({
      cartOpen: !prevState.cartOpen
    }));

  renderCartItems = cartItems => (
    <ul>
      {cartItems.map(cartItem => {
        const { products } = this.props;
        const productItem = products.find(
          item => parseInt(item.id) === cartItem.productId
        );
        return (
          <li key={`side-cart-item-${cartItem.id}`}>
            <Container>
              <Row className="align-items-center">
                <Col className={styles.imageCol}>
                  <img src={_.get(cartItem, "images[0]")} />
                </Col>
                <Col className={styles.detailCol}>
                  <h3 className={styles.productName}>
                    {isEn() ? cartItem.name : cartItem.cn}
                  </h3>
                  <div className={styles.price}>
                    {DiscountPrice({
                      originalPrice: cartItem.price,
                      discount: cartItem.discount
                    })}
                  </div>
                  <AddToCart cartItem={cartItem} productItem={productItem} />
                </Col>
              </Row>
            </Container>
          </li>
        );
      })}
    </ul>
  );

  authButtons = () => {
    const { t, me } = this.props;

    if (!me.id) {
      return (
        <div className={styles.authBtns}>
          <Button variant="gradient" onClick={() => history.push("/login")}>
            <FontAwesomeIcon size="lg" icon={faUserCircle} /> {t(["nav.login"])}
          </Button>
          <Button variant="gradient" onClick={() => history.push("/signup")}>
            <FontAwesomeIcon size="lg" icon={faUserCircle} />{" "}
            {t(["nav.signup"])}
          </Button>
        </div>
      );
    }
  };

  render() {
    const { cartOpen } = this.state;
    const { cartItems, cartTotals, me, t } = this.props;
    return (
      <div
        ref={this.setWrapperRef}
        className={`floatingCartWrapper ${styles.floatingCartWrapper} ${
          cartOpen ? styles.cartOpen : styles.cartClosed
        }`}
      >
        <Button onClick={this.handleCartTrigger} className={styles.cartTrigger}>
          {cartOpen ? (
            <FontAwesomeIcon icon={faTimes} />
          ) : (
            <FontAwesomeIcon icon={faShoppingCart} />
          )}
        </Button>
        <div className={styles.cartContent}>
          <header>
            <h2 className={styles.heading}>
              <FontAwesomeIcon icon={faShoppingCart} /> {t("shoppingCart")}
            </h2>
            <p>
              {cartItems.length} {t("itemsInCart")}
            </p>
            {this.authButtons()}
          </header>
          <section className="mb-4">{this.renderCartItems(cartItems)}</section>
          <footer>
            <Container>
              <Row className="align-items-bottom">
                <Col>
                  <p>{t("total")}</p>
                </Col>
                <Col>
                  <div className={styles.subtotal}>
                    {DiscountPrice({
                      originalPrice: cartTotals.cartTotalOriginalPrice,
                      finalPrice: cartTotals.cartTotalPrice
                    })}
                  </div>
                </Col>
              </Row>
              {cartItems.length > 0 && (
                <Row className={styles.checkoutBtnWrapper}>
                  <Button
                    variant="gradient"
                    onClick={() => history.push("/checkout")}
                  >
                    <FontAwesomeIcon icon={faShoppingCart} />
                    {t(["checkout.title"])}
                  </Button>
                </Row>
              )}
            </Container>
          </footer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cartItems: _.get(state.cartReducer, "cartItems", []),
    cartTotals: _.get(state.cartReducer, "cartTotals", {}),
    products: _.get(state.productReducer, "products", []),
    me: _.get(state.meReducer, "me", {})
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCart,
      setCartTotal
    },
    dispatch
  );

// To promote a component to a container (smart component) - it needs
// to know about this new dispatch method. Make it available
// as a prop.
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CartFloating)
);
