import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles";
import Invoice from "../../Invoice";
import history from "../../../lib/history";
import { setPaidOrder } from "../../../pages/Checkout/actions";

class Success extends React.Component {
  componentWillUnmount() {
    this.props.setPaidOrder(null);
  }

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Col className={styles.continueShoppingCol}>
          <Button
            variant="gradient"
            onClick={() => window.location.assign("/")}
          >
            {t(["invoice.continueShopping"])}
          </Button>
        </Col>
        <Card className="custom-card">
          <Card.Body>
            <Row>
              <Col className={styles.wrapper}>
                <FontAwesomeIcon size="4x" color="green" icon={faCheckCircle} />
                <h2>{t(["invoice.title"])}</h2>
              </Col>
            </Row>
            <hr />
            <Row>
              <Invoice order={this.props.order} />
            </Row>
          </Card.Body>
        </Card>
        <Col className={styles.continueShoppingCol}>
          <Button
            variant="gradient"
            onClick={() => window.location.assign("/")}
          >
            {t(["invoice.continueShopping"])}
          </Button>
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    order: _.get(state.checkoutReducer, "paidOrder")
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPaidOrder,
    },
    dispatch
  );

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Success));
