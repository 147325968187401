import React, { useEffect, useState } from "react";
import { Elements } from "react-stripe-elements";
import { StripeProvider } from "react-stripe-elements";
import StripeForm from "./StripeForm";

export default function Payment(props) {
  return (
    <StripeProvider apiKey={process.env.STRIPE_PUBLIC_KEY}>
      <Elements>
        <StripeForm client={props.client} />
      </Elements>
    </StripeProvider>
  );
}
