import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";

import AddToCart from "../AddToCart";
import {
  moneyStr,
  discountPrice,
  transformImage,
  isEn,
  quantityBundleDiscount,
  stockRibbon
} from "../../lib";
import DiscountPrice from "../DiscountPrice";
import styles from "./styles.module.scss";

const ProductTileItem = ({
  cartItem,
  productItem,
  showDesc,
  imageSize,
  quantity
}) => {
  const { t } = useTranslation();

  const renderQuantityBundle = quantityBundles => {
    if (!_.isEmpty(quantityBundles)) {
      const ascQuantityBundles = quantityBundles.sort(
        (a, b) => a.quantity - b.quantity
      );
      return (
        <span>
          {isEn() ? ascQuantityBundles[0].name : ascQuantityBundles[0].cn}
        </span>
      );
    }
    return null;
  };

  // Deprecated for now
  const renderDiscountLabel = (discount, quantityBundles) => {
    if (!_.isEmpty(quantityBundles)) {
      return (
        <div className={styles.ribbonAlt}>
          <span>{t("quantityBundles")}</span>
        </div>
      );
    }
    return null;
  };

  const finalPrice = discountPrice(
    discountPrice(productItem.price, productItem.discount),
    quantityBundleDiscount(productItem.quantityBundles, productItem.quantity)
  );

  return (
    <div className={styles.productTile}>
      <Card>
        <Card.Body>
          {stockRibbon(productItem.stock, t)}
          <div className={styles.imageWrapper}>
            <LinkContainer to={`/product/${productItem.id}`}>
              <Card.Link>
                <Card.Img
                  src={transformImage(productItem.images[0], imageSize)}
                />
              </Card.Link>
            </LinkContainer>
          </div>
          <div className={styles.name}>
            <LinkContainer to={`/product/${productItem.id}`}>
              <a className={styles.link}>
                {isEn() ? productItem.name : productItem.cn}
              </a>
            </LinkContainer>
          </div>
          <div className={styles.price}>
            <DiscountPrice
              originalPrice={productItem.price}
              finalPrice={finalPrice}
            />
          </div>
          {showDesc && productItem.description && (
            <Card.Text>
              {isEn() ? productItem.description : productItem.descCn}
            </Card.Text>
          )}
          <Card.Text className={styles.qb}>
            {renderQuantityBundle(productItem.quantityBundles)}
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <AddToCart cartItem={cartItem} productItem={productItem} />
        </Card.Footer>
      </Card>
    </div>
  );
};

ProductTileItem.defaultProps = {
  cartItem: {},
  product: {},
  showDesc: false,
  imageSize: null,
  quantity: 0
};

ProductTileItem.propTypes = {
  handleAddToCart: PropTypes.func,
  handleUpdateCart: PropTypes.func,
  initialQuantity: PropTypes.number,
  cartItem: PropTypes.object,
  product: PropTypes.object,
  showDesc: PropTypes.bool,
  imageSize: PropTypes.string,
  quantity: PropTypes.number
};

export default ProductTileItem;
