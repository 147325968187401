import React, { Component } from "react";
import LazyLoad from "react-lazyload";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Slider from "react-slick";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CustomInfiniteScroll from "../CustomInfiniteScroll";
import ProductTileItem from "../ProductTileItem";
import ProductCategoryItem from "../ProductCategoryItem";
import GenericLoader from "../GenericLoader";
import CustomArrowButton from "../CustomArrowButton";

import { setCart, setCartTotal } from "../CartFloating/actions";
import { ADD_TO_CART, UPDATE_CART } from "../../mutations/cart";
import { gqErrorMsg } from "../../lib";
import styles from "./styles.module.scss";

class ProductList extends Component {
  renderItem = productItem => {
    const { cartItems, display, showDesc, imageSize } = this.props;
    const cartItem = cartItems.find(
      item => parseInt(productItem.id) === item.productId
    );

    return (
      <ProductTileItem
        key={`${display}-${productItem.id}`}
        cartItem={cartItem}
        productItem={productItem}
        showDesc={showDesc}
        imageSize={imageSize}
      />
    );
  };

  renderItems = dataToRender => {
    const {
      products,
      title,
      styleMode,
      direction,
      numberOfCards,
      loading,
      moreLink,
      infiniteScroll
    } = this.props;

    return (
      <div
        data-product-count={products.length}
        className={`${styles.productTileListWrapper} ${
          styleMode === "DARK" ? styles.dark : styles.light
        }`}
      >
        <Container expand="lg">
          <Row className={styles.header}>
            {title && <h2 className={styles.title}>{_.capitalize(title)}</h2>}
            {moreLink && (
              <LinkContainer to={moreLink.href}>
                <Button variant="gradient">{moreLink.text}</Button>
              </LinkContainer>
            )}
          </Row>
          {direction === "HORIZONTAL" && (
            <div className={styles.carouselWrapper}>
              {loading ? (
                <GenericLoader />
              ) : (
                <Slider {...ProductList.initialiseSlider(numberOfCards)}>
                  {dataToRender}
                </Slider>
              )}
            </div>
          )}

          {direction === "VERTICAL" && (
            <div className={styles.carouselWrapper}>
              {loading ? <GenericLoader /> : <div>{dataToRender}</div>}
            </div>
          )}

          {direction === "TILES" && (
            <div className={styles.carouselWrapper}>
              {loading ? (
                <GenericLoader />
              ) : infiniteScroll ? (
                dataToRender
              ) : (
                <div className={styles.tileList}>{dataToRender}</div>
              )}
            </div>
          )}
        </Container>
      </div>
    );
  };

  render() {
    const {
      products,
      title,
      styleMode,
      display,
      direction,
      numberOfCards,
      loading,
      cartItems,
      showDesc,
      imageSize,
      moreLink,
      infiniteScroll,
      lazy
    } = this.props;

    let dataToRender = [];

    const filteredProducts = numberOfCards
      ? products.slice(0, numberOfCards)
      : products;

    if (_.isEmpty(filteredProducts)) {
      return null;
    }

    if (display === "PRODUCT") {
      if (infiniteScroll) {
        dataToRender = (
          <CustomInfiniteScroll
            allData={filteredProducts}
            renderItem={this.renderItem}
            customStyle={styles.tileList}
          />
        );
      } else {
        dataToRender = filteredProducts.map(productItem => {
          const cartItem = cartItems.find(
            item => parseInt(productItem.id) === item.productId
          );
          return (
            <ProductTileItem
              key={`${display}-${productItem.id}`}
              cartItem={cartItem}
              productItem={productItem}
              showDesc={showDesc}
              imageSize={imageSize}
            />
          );
        });
      }
    }

    if (lazy) {
      <LazyLoad once offset={200}>
        {this.renderItems(dataToRender)}
      </LazyLoad>;
    }

    return <React.Fragment>{this.renderItems(dataToRender)}</React.Fragment>;
  }
}

ProductList.initialiseSlider = numberOfCards => {
  return {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: numberOfCards,
    slidesToScroll: 1,
    lazyLoad: true,
    prevArrow: <CustomArrowButton />,
    nextArrow: <CustomArrowButton left={false} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 522,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
};

ProductList.defaultProps = {
  title: "Default",
  ctaText: "Button CTA",
  display: "PRODUCTS",
  direction: "HORIZONTAL",
  styleMode: "LIGHT",
  numberOfCards: 0,
  loading: false,
  products: [],
  showDesc: false,
  imageSize: null,
  lazy: false
};

ProductList.propTypes = {
  title: PropTypes.string,
  ctaText: PropTypes.string,
  display: PropTypes.string,
  direction: PropTypes.string,
  styleMode: PropTypes.string,
  numberOfCards: PropTypes.number,
  products: PropTypes.array,
  loading: PropTypes.bool,
  discount: PropTypes.number,
  showDesc: PropTypes.bool,
  imageSize: PropTypes.string,
  lazy: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    cartItems: _.get(state.cartReducer, "cartItems", []),
    me: _.get(state.meReducer, "me", {})
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCart,
      setCartTotal
    },
    dispatch
  );

// To promote a component to a container (smart component) - it needs
// to know about this new dispatch method. Make it available
// as a prop.
export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
