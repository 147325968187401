import {
  SET_DELIVERY_TIME_ID,
  SET_DELIVERY_DATE,
  SET_DELIVERY_TIME_RANGE,
  SET_CANONICAL_ADDRESS_ID,
  SET_FULL_ADDRESS,
  SET_PAYMENT_INTENT,
  SET_QR_CODE,
  SET_PENDING_ORDER,
  SET_DELIVERY_COST,
  SET_ADDRESS_API_META_DATA,
  CLEAR_CHECKOUT_META,
  CLEAR_CHECKOUT,
  SET_COUPONS,
  SET_NOTE,
  SET_DELIVERY_TIMES_PER_LOCATION,
  SET_LOCATION,
  SET_AVAILABLE_COUPONS,
  SET_PAID_ORDER
} from "../pages/Checkout/actions";
import React from "react";

const DEFAULT_APP_SCHEMA = {
  meta: {
    deliveryTimeId: null,
    deliveryTimeRange: null,
    deliveryDate: null,
    canonicalAddressId: null,
    fullAddress: null,
    deliveryCost: {
      price: null,
      discount: null,
      totalPrice: null
    }
  },
  apiMeta: null,
  paymentIntent: null,
  pendingOrder: null,
  selectedCoupon: null,
  note: null,
  deliveryTimesPerLocation: {},
  location: null,
  availableCoupons: [],
  selectedCoupons: [],
};

export default function(state = DEFAULT_APP_SCHEMA, action) {
  switch (action.type) {
    case SET_DELIVERY_TIME_ID:
      return {
        ...state,
        meta: {
          ...state.meta,
          deliveryTimeId: parseInt(action.payload, 10)
        }
      };
    case SET_DELIVERY_DATE:
      return {
        ...state,
        meta: {
          ...state.meta,
          deliveryDate: action.payload
        }
      };
    case SET_DELIVERY_TIME_RANGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          deliveryTimeRange: action.payload
        }
      };
    case SET_DELIVERY_COST:
      return {
        ...state,
        meta: {
          ...state.meta,
          deliveryCost: action.payload
        }
      };
    case SET_CANONICAL_ADDRESS_ID:
      return {
        ...state,
        meta: {
          ...state.meta,
          canonicalAddressId: action.payload
        }
      };
    case SET_FULL_ADDRESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          fullAddress: action.payload
        }
      };
    case SET_PAYMENT_INTENT:
      return {
        ...state,
        paymentIntent: action.payload
      };
    case SET_QR_CODE:
      return {
        ...state,
        qrCode: action.payload
      };
    case SET_PENDING_ORDER:
      return {
        ...state,
        pendingOrder: action.payload
      };
    case SET_PAID_ORDER:
      return {
        ...state,
        paidOrder: action.payload
      };
    case SET_ADDRESS_API_META_DATA:
      return {
        ...state,
        apiMeta: action.payload
      };
    case CLEAR_CHECKOUT_META:
      return {
        ...state,
        meta: DEFAULT_APP_SCHEMA.meta
      };
    case CLEAR_CHECKOUT:
      return {
        ...state,
        ...DEFAULT_APP_SCHEMA
      };
    case SET_COUPONS:
      return {
        ...state,
        selectedCoupons: action.payload
      };
    case SET_NOTE:
      return {
        ...state,
        note: action.payload
      };
    case SET_DELIVERY_TIMES_PER_LOCATION:
      return {
        ...state,
        deliveryTimesPerLocation: action.payload
      };
    case SET_LOCATION:
      return {
        ...state,
        location: action.payload
      };
    case SET_AVAILABLE_COUPONS:
      return {
        ...state,
        availableCoupons: action.payload
      };
    default:
      return state;
  }
}
