import gql from "graphql-tag";

export const GET_LOCATIONS = gql`
  query GetLocations($suburb: String, $postcode: String, $fullAddress: String) {
    locations(suburb: $suburb, postcode: $postcode, fullAddress: $fullAddress) {
      id
      deliveryDays
      suburb
      postcode
      state
      status
      deadlineHour
      deadlineMinute
      deadlineMeridiem
      deadline
      contextedDeliveryTimes {
        id
        from
        to
        timeOfDay
        note
        price
        totalPrice
        discount
        status
        featured
      }
    }
  }
`;
