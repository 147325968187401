import React from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

/**
 * @module Layout
 */
const Layout = props => {
  const { t } = useTranslation();
  const { title, children } = props;
  return (
    <div className={styles.layoutWrapper}>
      <Container>
        {title && (
          <Row>
            <Col>
              {title && (
                <h1 className={styles.title}>{t([`${title}.title`])}</h1>
              )}
            </Col>
          </Row>
        )}
        <Row>
          <Col>{children}</Col>
        </Row>
      </Container>
    </div>
  );
};

Layout.defaultProps = {
  defaultPropGoesHere: "default prop",
  title: ""
};

Layout.propTypes = {
  defaultPropGoesHere: PropTypes.string,
  title: PropTypes.string
};

export default Layout;
