import "core-js/stable";
import "regenerator-runtime/runtime";
import fetch from "unfetch";
import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  concat,
  ApolloProvider
} from "@apollo/client";
import Cookies from "universal-cookie";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/client";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import styles from "./styles.module.scss";
import Container from "react-bootstrap/Container";
import Home from "../../pages/Home";
import Checkout from "../../pages/Checkout";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Login from "../../pages/Login";
import Signup from "../../pages/Signup";
import ForgotPassword from "../../pages/ForgotPassword";
import ResetPassword from "../../pages/ResetPassword";
import ProductShow from "../../pages/Products/Show";
import ProductList from "../../pages/Products/List";
import Contact from "../../pages/Contact";
import reducers from "../../reducers";
import Orders from "../../components/Me/Orders";
import Profile from "../../components/Me/Profile";
import Coupons from "../../components/Me/Coupons";
import Payment from "../../components/Payment";
import About from "../../pages/About";
import Support from "../../pages/Support";
import historySingleton from "../../lib/history";
import i18n from "../../lib/i18n";
import ScrollToTop from "../../lib/reactRouterScrollToTop";

const httpLink = new HttpLink({
  uri: "/graphql",
  credentials: "same-origin",
  fetch
});

const csrfToken = document
  .querySelector("meta[name=csrf-token]")
  .getAttribute("content");

/* REDUX */
const reduxMiddleware = [];
const consoleStyle = "background: #555; color: #bada55";
const ENABLE_DEV_TOOLS = process.env.NODE_ENV === "development" || false;

let devToolsExtension = f => f; // Initialise as an empty function

if (ENABLE_DEV_TOOLS) {
  console.log("%c_____ PROJECT HAS REDUX TOOLS ENABLED _____", consoleStyle);
  devToolsExtension = window.devToolsExtension
    ? window.devToolsExtension()
    : f => f;
}
const middlewareComposed = compose(
  applyMiddleware(...reduxMiddleware),
  devToolsExtension
);

const store = createStore(reducers, middlewareComposed);

/* Apollo */
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: localStorage.getItem("token") || null,
      "X-CSRF-Token": csrfToken
    }
  });

  return forward(operation);
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore"
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all"
  }
};

const client = new ApolloClient({
  fetchOptions: {
    credentials: "same-origin"
  },
  cache: new InMemoryCache(),
  defaultOptions,
  link: concat(authMiddleware, httpLink)
});

const cookies = new Cookies();

export default class SmallCommerce extends React.Component {
  componentWillMount() {
    i18n.changeLanguage(cookies.get("lanPreference") || "en");
  }

  render() {
    return (
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <Provider store={store}>
            <div>
              <Router history={historySingleton}>
                <Header client={client} />
                <ScrollToTop />
                <Switch>
                  <Route exact path="/">
                    <Home client={client} />
                  </Route>
                  <Route path="/checkout">
                    <Layout title="checkout">
                      <Checkout client={client} />
                    </Layout>
                  </Route>
                  <Route
                    path="/login"
                    children={({ history }) => (
                      <Layout>
                        <Login history={history} />
                      </Layout>
                    )}
                  />
                  <Route
                    path="/signup"
                    children={({ history }) => (
                      <Layout>
                        <Signup history={history} />
                      </Layout>
                    )}
                  />
                  <Route
                    path="/forgot-password"
                    children={({ history }) => (
                      <Layout>
                        <ForgotPassword history={history} />
                      </Layout>
                    )}
                  />
                  <Route
                    path="/reset-password"
                    children={({ history, match }) => (
                      <Layout title="resetPassword">
                        <ResetPassword token={this.props.token} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/products"
                    children={({ history, match }) => (
                      <Layout>
                        <ProductList
                          client={client}
                          history={history}
                          match={match}
                        />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/products/:filter"
                    children={({ history, match }) => (
                      <Layout>
                        <ProductList
                          client={client}
                          history={history}
                          match={match}
                        />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/product/:productId"
                    children={({ history, match }) => (
                      <Layout>
                        <ProductShow
                          client={client}
                          history={history}
                          match={match}
                        />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/me/placed-orders"
                    children={({ history, match }) => (
                      <Layout title="placedOrders">
                        <Orders
                          client={client}
                          match={match}
                          status="paid"
                          title="Placed Orders"
                        />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/me/dispatched-orders"
                    children={({ history, match }) => (
                      <Layout title="dispatchedOrders">
                        <Orders
                          client={client}
                          match={match}
                          status="dispatched"
                          title="Dispatched Orders"
                        />
                      </Layout>
                    )}
                  />
                  <Route exact path="/me">
                    <Redirect to="/me/profile" />
                  </Route>
                  <Route
                    exact
                    path="/me/profile"
                    children={({ history, match }) => (
                      <Layout title="profile">
                        <Profile
                          client={client}
                          history={history}
                          match={match}
                        />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/me/coupons"
                    children={({ history, match }) => (
                      <Layout title="coupons">
                        <Coupons client={client} history={history} />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/payment"
                    children={({ history, match }) => (
                      <Layout title="payment">
                        <Payment
                          stripePublicKey={this.props.stripePublicKey}
                          client={client}
                          history={history}
                          match={match}
                        />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/contact"
                    children={({ history, match }) => (
                      <Layout title="contact">
                        <Contact />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/about"
                    children={({ history, match }) => (
                      <Layout title="about">
                        <About />
                      </Layout>
                    )}
                  />
                  <Route
                    exact
                    path="/support"
                    children={({ history, match }) => (
                      <Layout title="support">
                        <Support />
                      </Layout>
                    )}
                  />
                </Switch>
                <Footer />
              </Router>
            </div>
          </Provider>
        </ApolloHooksProvider>
      </ApolloProvider>
    );
  }
}
