import {
  SET_PRODUCTS,
  SET_CATEGORIES,
  SET_DEFAULT_CATEGORIES
} from "../components/Header/actions";

const DEFAULT_APP_SCHEMA = {
  products: [],
  categories: [],
  defaultCategories: []
};

export default function(state = DEFAULT_APP_SCHEMA, action) {
  switch (action.type) {
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    case SET_DEFAULT_CATEGORIES:
      return {
        ...state,
        defaultCategories: action.payload
      };
    default:
      return state;
  }
}
