import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";

const Support = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Row>
        <Card>
          <Card.Body>
            <Row>
              <Col md="auto">
                <h5><a href="/Delivery-Area-Updated-23-3-20.pdf">{t(["support.deliveryArea"])}</a></h5>
              </Col>
              <Col md="auto">
                <h5><a href="/return_policy.docx">{t(["support.returnPolicy"])}</a></h5>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
};

export default Support;
