import React from "react";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";

import { CREATE_CUSTOMER } from "../../mutations/users";
import { gqErrorMsg } from "../../lib";
import GenericLoader from "../../components/GenericLoader";

const Signup = ({ history }) => {
  const { t } = useTranslation();
  const {
    register,
    errors,
    handleSubmit,
    setError,
    formState: { isSubmitting }
  } = useForm();
  const [createCustomer, { error }] = useMutation(CREATE_CUSTOMER, {
    onCompleted() {
      window.location.assign("/");
    }
  });
  const onSubmit = async data => {
    if (data.passwordConfirmation != data.password) {
      return setError(
        "passwordConfirmation",
        "passwordMismatch",
        "Password confirmation doesn't match"
      );
    }
    await createCustomer({
      variables: {
        name: data.name,
        email: data.email,
        password: data.password,
        phone: data.phone
      }
    });
  };

  return (
    <Col md={{ span: 6, offset: 3 }}>
      <h1 className="mb-4 mt-5">{t(["signup.title"])}</h1>
      <form method="POST" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="name" bsSize="large">
          <Form.Label>{t(["signup.name"])}</Form.Label>
          <Form.Control
            autoFocus
            type="name"
            name="name"
            ref={register({ required: true })}
          />
          {errors.name && <p className="form-error">This is required</p>}
        </Form.Group>
        <Form.Group controlId="phone" bsSize="large">
          <Form.Label>{t(["signup.phone"])}</Form.Label>
          <Form.Control
            autoFocus
            type="number"
            name="phone"
            ref={register({ required: true })}
          />
          {errors.phone && <p className="form-error">This is required</p>}
        </Form.Group>
        <Form.Group controlId="email" bsSize="large">
          <Form.Label>{t(["signup.email"])}</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            name="email"
            ref={register({ required: true })}
          />
          {errors.email && <p className="form-error">This is required</p>}
        </Form.Group>
        <Form.Group controlId="password" bsSize="large">
          <Form.Label>{t(["signup.password"])}</Form.Label>
          <Form.Control
            type="password"
            name="password"
            ref={register({ required: true })}
          />
          {errors.password && <p className="form-error">This is required</p>}
        </Form.Group>
        <Form.Group controlId="password" bsSize="large">
          <Form.Label>{t(["signup.passwordConfirmation"])}</Form.Label>
          <Form.Control
            type="password"
            name="passwordConfirmation"
            ref={register({ required: true })}
          />
          {_.get(errors, "passwordConfirmation.message")}
        </Form.Group>

        <Button block bsSize="large" type="submit" disabled={isSubmitting}>
          {t(["signup.title"])}
        </Button>
        {isSubmitting && <GenericLoader />}
        {error && <Alert variant="danger">{gqErrorMsg(error)}</Alert>}
      </form>
    </Col>
  );
};

export default Signup;
