import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperclip,
  faMapMarkerAlt,
  faHashtag,
  faCalendarAlt
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Alert from "react-bootstrap/Alert";
import LayoutSideNav from "../../LayoutSideNav";
import { GET_COUPONS } from "../../../queries/coupons";
import GenericLoader from "../../GenericLoader";
import Coupon from "../../Coupon";
import { gqErrorMsg, couponDisplayValue, isEn } from "../../../lib";
import styles from "./styles";

const Coupons = ({ client, status }) => {
  const [coupons, setCoupons] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const fetchCoupons = () => {
    setSuccess(null);
    setError(null);
    client
      .query({
        query: GET_COUPONS
      })
      .then(res => {
        setLoading(false);
        setCoupons(_.get(res, "data.coupons", []));
      })
      .catch(errorResp => {
        setLoading(false);
        setError(gqErrorMsg(errorResp));
      });
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  return (
    <LayoutSideNav history={history}>
      <Container>
        {loading && <GenericLoader />}
        <h4>{t(["coupons.validCoupons"])}</h4>
        <div className={styles.couponWrapper}>
          <Col md={9}>
            {success && <Alert variant="success">{success}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}
            <ListGroup>
              {coupons.map(coupon => {
                return (
                  <ListGroup.Item key={`coupon-${coupon.id}`} action>
                    <Coupon coupon={coupon} />
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Col>
        </div>
      </Container>
    </LayoutSideNav>
  );
};

export default Coupons;
