import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import styles from "./styles.module.scss";

const AuthFloating = ({ me }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      {me.id ? (
        <div className={styles.meBtn}>
          <Nav.Link variant="link" href="/me/profile">
            <FontAwesomeIcon size="lg" icon={faUserCircle} /> {t("nav.me")}
          </Nav.Link>
        </div>
      ) : (
        <div className={styles.authBtns}>
          <LinkContainer to="/login">
            <Nav.Link>
              <FontAwesomeIcon size="lg" icon={faUserCircle} /> {t("nav.login")}
            </Nav.Link>
          </LinkContainer>
          /
          <LinkContainer to="/signup">
            <Nav.Link>{t("nav.signup")}</Nav.Link>
          </LinkContainer>
        </div>
      )}
    </div>
  );
};

export default AuthFloating;
