import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";

const EditActionButtons = ({ handleEditingState }) => {
  return (
    <div className={styles.actionButtons}>
      <Row>
        <Col md={{ span: 8, order: 12 }}>
          <Button className="mb-2 mb-md-0" variant="gradient" type="submit">
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            Save
          </Button>
        </Col>
        <Col md={{ span: 4, order: 1 }}>
          <Button variant="link" type="button" onClick={handleEditingState}>
            <FontAwesomeIcon icon={faTimes} className="mr-2" />
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
};

EditActionButtons.propTypes = {};

export default EditActionButtons;
