import gql from "graphql-tag";

export const GET_PRODUCTS = gql`
  query fetchProducts($id: ID) {
    products(id: $id) {
      id
      name
      cn
      stock
      featured
      topSeller
      price
      images
      description
      descCn
      shorthand
      pos
      categories {
        id
        name
        cn
        key
        status
      }
      quantityUnit {
        id
        name
      }
      discount
      purchaseLimit
      quantityBundles {
        id
        name
        cn
        quantity
        percentage
        productId
      }
    }
  }
`;
