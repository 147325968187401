import React from "react";
import { useTranslation } from "react-i18next";
import ListGroup from "react-bootstrap/ListGroup";
import CartFloating from "../../../components/CartFloating";
import Logout from "../Logout";
import styles from "./styles.module.scss";
import { LinkContainer } from "react-router-bootstrap";

const SideMenu = ({ history }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.sideNavWrapper}>
      <ListGroup className="mb-5 mb-md-0" variant="flush">
        <LinkContainer key="profile-link" to="/me/profile">
          <ListGroup.Item action>{t(["me.menu.profile"])}</ListGroup.Item>
        </LinkContainer>
        <LinkContainer key="placed-orders-link" to="/me/placed-orders">
          <ListGroup.Item action>{t(["me.menu.placedOrders"])}</ListGroup.Item>
        </LinkContainer>
        <LinkContainer key="dispatched-orders-link" to="/me/dispatched-orders">
          <ListGroup.Item action>
            {t(["me.menu.dispatchedOrders"])}
          </ListGroup.Item>
        </LinkContainer>
        <LinkContainer key="coupons-link" to="/me/coupons">
          <ListGroup.Item action>{t(["me.menu.coupons"])}</ListGroup.Item>
        </LinkContainer>
        <Logout history={history} />
      </ListGroup>
      <CartFloating />
    </div>
  );
};

export default SideMenu;
