import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import { faSignOutAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { LinkContainer } from "react-router-bootstrap";
import { withTranslation } from "react-i18next";

import { setCart, setCartTotal } from "../../CartFloating/actions";
import { setMe } from "../../Header/actions";
import { LOGOUT } from "../../../queries/me";
import { gqErrorMsg } from "../../../lib";

class Logout extends Component {
  logout = () => {
    this.props.client
      .mutate({
        mutation: LOGOUT
      })
      .then(() => {
        this.props.setMe({});
        this.props.setCart([]);
        this.props.setCartTotal(0, 0);
        this.props.history.push("/");
      })
      .catch(({ graphQLErrors }) => {
        // replace with redux
        this.setState({
          error: gqErrorMsg(graphQLErrors)
        });
      });
  };

  render() {
    const { t } = this.props;
    if (_.get(this.props, "me.id")) {
      return (
        <ListGroup.Item action onClick={() => this.logout()}>
          <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: "5px" }} />
          {t(["me.menu.logout"])}
        </ListGroup.Item>
      );
    }
    return (
      <LinkContainer to="/login">
        <ListGroup.Item action>
          <FontAwesomeIcon icon={faUserCircle} style={{ marginRight: "5px" }} />
          {t(["me.nav.login"])}
        </ListGroup.Item>
      </LinkContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    client: _.get(state.appReducer, "client", {}),
    me: _.get(state.meReducer, "me", {})
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCart,
      setMe,
      setCartTotal
    },
    dispatch
  );

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Logout)
);
