import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import styles from "./styles.module.scss";

const Counter = ({
  onUpdateCart,
  quantity,
  stock,
  purchaseLimit
}) => {
  const debouncedCartUpdate = _.debounce(quantity => onUpdateCart(quantity), 500);

  return (
    <div className={styles.counterWrapper}>
      <InputGroup>
        <InputGroup.Prepend>
          <Button
            variant="gradient"
            onClick={() => debouncedCartUpdate(quantity > 0 ? quantity - 1 : 0)}
          >
            <FontAwesomeIcon icon={faMinus} />
          </Button>
        </InputGroup.Prepend>
        <FormControl
          className={styles.quantityInput}
          value={quantity}
          placeholder="Quantity"
          aria-label="Quantity"
          onBlur={() => debouncedCartUpdate(quantity)}
          aria-describedby="product-quantity"
          readOnly
        />
        <InputGroup.Append>
          <Button
            disabled={
              quantity >= purchaseLimit || quantity >= stock
            }
            variant="gradient"
            onClick={() => debouncedCartUpdate(quantity + 1)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
};

Counter.defaultProps = {
  onUpdateCart: f => f,
  purchaseLimit: 15
};

export default Counter;
