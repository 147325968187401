import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faWeixin
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import wechatSrc from "./afm_wechat.jpeg";
import styles from "./styles";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Row>
        <Card className={`custom-card ${styles.margin}`}>
          <Card.Body>
            <Row>
              <Col md="4" className={styles.col}>
                <div className={styles.tile}>
                  <FontAwesomeIcon size="4x" color="white" icon={faEnvelope} />
                  <h4>{t(["contact.email"])}</h4>
                  info@ausfreshmeat.com.au
                </div>
              </Col>
              <Col md="4" className={styles.col}>
                <div className={styles.tile}>
                  <FontAwesomeIcon size="4x" color="white" icon={faPhone} />
                  <h4>{t(["contact.call"])}</h4>
                  03 9372 1288
                </div>
              </Col>
              <Col md="4" className={styles.col}>
                <div className={styles.tile}>
                  <img src={wechatSrc} />
                  <h4>{t(["contact.wechat"])}</h4>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
};

export default Contact;
