import gql from "graphql-tag";

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $email: String
    $name: String
    $phone: String
    $status: String
    $password: String
  ) {
    updateUser(
      input: {
        id: $id
        email: $email
        name: $name
        phone: $phone
        status: $status
        password: $password
      }
    ) {
      user {
        id
        name
        email
        phone
        addresses {
          canonicalAddressId
          fullAddress
          addressableId
          addressableType
        }
      }
    }
  }
`;

export const LOGIN = gql`
  mutation loginCustomer($email: String!, $password: String!) {
    loginUser(input: { email: { email: $email, password: $password } }) {
      user {
        id
      }
      token
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation createCustomer(
    $name: String!
    $email: String!
    $password: String!
    $phone: String!
  ) {
    createUser(
      input: {
        name: $name
        phone: $phone
        authProvider: { email: { email: $email, password: $password } }
      }
    ) {
      user {
        id
      }
    }
  }
`;

export const EMAIL_RESET_PASSWORD = gql`
  mutation sendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(input: { email: $email }) {
      clientMutationId
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $token: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    resetPassword(
      input: {
        token: $token
        password: $password
        passwordConfirmation: $passwordConfirmation
      }
    ) {
      user {
        id
        name
      }
    }
  }
`;

export const DELETE_ADDRESS = gql`
  mutation deleteAddress($id: ID!) {
    deleteAddress(input: { id: $id }) {
      clientMutationId
    }
  }
`;
