import React from "react";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const CustomPagination = ({
  totalPages,
  onPageChange,
  page,
  marginPagesDisplayed,
  maxPageDisplay
}) => {
  if (totalPages === 0) return null;

  return (
    <div className={styles.paginationWrapper}>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        breakClassName={styles.breakStyle}
        pageCount={totalPages}
        forcePage={page - 1}
        marginPagesDisplayed={marginPagesDisplayed}
        pageRangeDisplayed={maxPageDisplay}
        onPageChange={onPageChange}
        containerClassName={styles.paginationContainer}
        subContainerClassName={styles.subContainer}
        activeClassName={styles.pageActive}
      />
    </div>
  );
};

CustomPagination.propTypes = {
  onClick: PropTypes.func,
  totalPages: PropTypes.number,
  maxPageDisplay: PropTypes.number
};

CustomPagination.defaultPropTypes = {
  onClick: f => f,
  totalPages: 0,
  marginPagesDisplayed: 1,
  maxPageDisplay: 4
};

export default CustomPagination;
