import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { MAP_BOX_STATIC_IMG } from "../../constants/URIs";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import _ from "lodash";

class AddressFinderAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.widget = null;
    this.address_line_1 = React.createRef();
    this.state = {
      addressMeta: {
        longitude: null,
        latitude: null
      }
    };
  }

  componentDidMount() {
    var script = document.createElement("script");
    script.src = "https://api.addressfinder.io/assets/v3/widget.js";
    script.async = true;
    script.onload = this.loadWidget;
    document.body.appendChild(script);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.fullAddress !== this.props.fullAddress) {
      this.address_line_1.current.value = nextProps.fullAddress;
    }
    return true;
  }

  componentWillUnmount() {
    if (this.widget) {
      this.widget.destroy();
      this.widget = null;
    }
  }

  loadWidget = () => {
    const { handleAddressSelection, apiMeta, locationTypes } = this.props;
    this.widget = new AddressFinder.Widget(
      document.getElementById("address_line_1"),
      process.env.AF_KEY,
      "AU",
      {
        address_params: {
          gnaf: "1",
          location_types: locationTypes,
          state_codes: "VIC"
        }
      }
    );

    if (!_.isEmpty(apiMeta)) {
      this.address_line_1.current.value = apiMeta.full_address;
      this.setState({ addressMeta: apiMeta });
    }

    this.widget.on("result:select", (fullAddress, metaData) => {
      this.address_line_1.current.value = metaData.full_address;
      this.address_line_1.current.disabled = true;
      this.setState({ addressMeta: metaData });
      handleAddressSelection(
        metaData.canonical_address_id,
        metaData.full_address,
        metaData
      );
    });
  };

  handleClearSelection = () => {
    this.setState({
      addressMeta: {
        longitude: null,
        latitude: null
      }
    });
    this.address_line_1.current.disabled = false;
    this.props.handleAddressCancel();
  };

  render() {
    const { fullAddress, t } = this.props;
    const { addressMeta } = this.state;
    const { latitude, longitude } = addressMeta;
    const placeHolderText = fullAddress || t("searchAddress");
    return (
      <form
        className="formBox"
        method="get"
        className={styles.autocompleteWrapper}
      >
        <Form.Group controlId="address_line_1">
          <InputGroup>
            <Form.Control
              type="search"
              name="address_line_1"
              className="formInput"
              placeholder={placeHolderText}
              disabled={fullAddress}
              ref={this.address_line_1}
            />
            <InputGroup.Append>
              <Button
                variant="gradient"
                onClick={() => this.handleClearSelection()}
              >
                <FontAwesomeIcon icon={faTimes} /> {t("clear")}
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        {latitude && longitude && (
          <img
            className={styles.image}
            width="600"
            src={MAP_BOX_STATIC_IMG(latitude, longitude)}
            alt="Mapbox Map of -73.7638,42.6564"
          />
        )}
      </form>
    );
  }
}

AddressFinderAutoComplete.propTypes = {
  locationTypes: PropTypes.string
};
AddressFinderAutoComplete.defaultPropTypes = {
  locationTypes: "street,locality,state"
};

const mapStateToProps = state => {
  return {
    apiMeta: _.get(state.checkoutReducer, "apiMeta", null)
  };
};
export default withTranslation()(
  connect(mapStateToProps)(AddressFinderAutoComplete)
);
