import React from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DiscountPrice from "../DiscountPrice";

import styles from "./styles";
import { moneyStr, isEn } from "../../lib";

const OrderItems = ({ orderItems }) => {
  const renderQuantityBundle = orderItem => {
    if (orderItem.quantityBundleName) {
      return (
        <p className={styles.quantityBundleWrapper}>
          <FontAwesomeIcon icon={faTag} />
          {isEn() ? orderItem.quantityBundleName : orderItem.quantityBundleCn}
        </p>
      );
    }
    return null;
  };

  return orderItems.map(item => {
    return (
      <ListGroup.Item
        className={styles.listGroupItem}
        key={`order-item-${item.id}`}
      >
        <Row>
          <Col xs={8} md={9}>
            <p>
              {item.quantity} x {isEn() ? item.name : item.cn || item.name}{" "}
              <span className={styles.discount}>
                {item.discount !== 0 && `(${item.discount}% off)`}
              </span>
            </p>
            {renderQuantityBundle(item)}
          </Col>
          <Col xs={4} md={3}>
            <DiscountPrice
              originalPrice={item.price * item.quantity}
              finalPrice={item.totalPrice}
            />
          </Col>
        </Row>
      </ListGroup.Item>
    );
  });
};

export default OrderItems;
