import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperclip,
  faMapMarkerAlt,
  faHashtag,
  faCalendarAlt,
  faPencilAlt
} from "@fortawesome/free-solid-svg-icons";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import OrderItems from "../OrderItems";
import { displayDeliveryTime, moneyStr } from "../../lib";
import DiscountPrice from "../DiscountPrice";
import styles from "./styles";

const Invoice = ({ order }) => {
  const { t } = useTranslation();

  const renderNote = () => {
    if (order.note) {
      return (
        <Row>
          <Col md="12" className={styles.orderRowCol}>
            <div className={styles.label}>
              <FontAwesomeIcon icon={faPencilAlt} />
              {t(["invoice.note"])}:
            </div>
            <div>{order.note}</div>
          </Col>
        </Row>
      );
    }
    return null;
  };

  return (
    <Container>
      <FontAwesomeIcon
        size="4x"
        className={styles.paperClip}
        icon={faPaperclip}
      />
      <Row className={styles.orderRow}>
        <Col md="12" className={styles.orderRowCol}>
          <div className={styles.label}>
            <FontAwesomeIcon icon={faHashtag} />
            {t(["invoice.orderNumber"])}:
          </div>
          <div>{order.orderNumber}</div>
        </Col>
      </Row>
      <Row>
        <Col md="12" className={styles.orderRowCol}>
          <div className={styles.label}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            {t("deliveryAddress")}:
          </div>
          <div>{order.deliveryAddress}</div>
        </Col>
      </Row>
      <Row>
        <Col md="12" className={styles.orderRowCol}>
          <div className={styles.label}>
            <FontAwesomeIcon icon={faCalendarAlt} />
            {t("deliveryDate")}:
          </div>
          <div>
            {displayDeliveryTime({
              deliveryTimeFrom: order.deliveryTimeFrom,
              deliveryTimeTo: order.deliveryTimeTo
            })}
          </div>
        </Col>
      </Row>
      {renderNote()}
      <hr />
      <Row>
        <Col className={styles.orderRowCol}>
          <div>{t("deliveryFee")}:</div>
          <div>{`$${moneyStr(order.deliveryPrice)}`}</div>
        </Col>
        <Col className={styles.orderRowCol}>
          <div>{t("orderItems")}:</div>
          <div>{`$${moneyStr(
            order.totalPrice - order.deliveryPrice + order.couponPrice
          )}`}</div>
        </Col>
      </Row>
      {(order.couponPrice || 0) > 0 && (
        <Row>
          <Col className={styles.orderRowCol}>
            <div>{t("coupon")}:</div>
            <div>{`$${moneyStr(order.couponPrice)}`}</div>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={{ span: 6, offset: 6 }} className={styles.orderRowCol}>
          <div>{t("total")}:</div>
          {DiscountPrice({
            finalPrice: order.totalPrice
          })}
        </Col>
      </Row>
      <OrderItems orderItems={order.orderItems} />
    </Container>
  );
};

Invoice.defaultProps = {
  order: {}
};

Invoice.propTypes = {
  order: PropTypes.object
};

export default Invoice;
