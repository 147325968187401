import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import Alert from "react-bootstrap/Alert";
import { injectStripe, CardElement } from "react-stripe-elements";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import styles from "./styles";
import { moneyStr, gqErrorMsg } from "../../../lib";
import PaymentSuccess from "../Success";
import { CONFIRM_PAYMENT } from "../../../mutations/payments";
import { GET_SINGLE_ORDER } from "../../../queries/me";
import { setCart, setCartTotal } from "../../CartFloating/actions";
import { clearCheckout, setPaidOrder } from "../../../pages/Checkout/actions";
import GenericLoader from "../../GenericLoader";
import history from "../../../lib/history";

class StripeForm extends React.Component {
  state = { subscribable: null, error: null, success: null, loading: false };

  componentDidMount() {
    const { client, orderNumber } = this.props;

    const observableQuery = client.watchQuery({
      query: GET_SINGLE_ORDER,
      variables: { orderNumber },
      pollInterval: 3000
    });

    const subscribable = observableQuery.subscribe({
      next: ({ data }) => {
        const paidOrder = _.get(data, "orders.nodes[0]");
        const orderStatus = _.get(data, "orders.nodes[0].status", null);
        const paymentType = _.get(data, "orders.nodes[0].paymentType", null);

        if (orderStatus === "paid" && paymentType === "wechat") {
          this.props.setCart([]);
          this.props.setCartTotal(0, 0);
          this.props.clearCheckout();
          this.props.setPaidOrder(paidOrder);
          this.setState({ success: true, loading: false });
        }
      }
    });
    this.setState({ subscribable });
  }

  componentWillUnmount() {
    this.state.subscribable && this.state.subscribable.unsubscribe();
  }

  handleSubmit = ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    // See our confirmCardPayment documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-payment
    this.setState({ error: null, success: null, loading: true });
    this.props.stripe
      .confirmCardPayment(this.props.clientSecret, {
        payment_method: {
          card: this.props.elements.getElement("card")
        }
      })
      .then(resp => {
        const stripeError = _.get(resp, "error.message");
        const stripeStatus = _.get(resp, "paymentIntent.status");
        if (stripeError) {
          this.setState({ error: stripeError, loading: false });
        } else if (stripeStatus === "succeeded") {
          this.props.client
            .mutate({
              variables: { orderNumber: this.props.orderNumber },
              mutation: CONFIRM_PAYMENT
            })
            .then(result => {
              const confirmStatus = _.get(result, "data.confirmPayment.status");
              if (confirmStatus === "succeeded") {
                const cartItems = _.get(
                  result,
                  "data.confirmPayment.cartItems"
                );
                const cartTotalOriginalPrice = _.get(
                  result,
                  "data.confirmPayment.cartTotalOriginalPrice",
                  0
                );
                const cartTotalPrice = _.get(
                  result,
                  "data.confirmPayment.cartTotalPrice",
                  0
                );
                const order = _.get(result, "data.confirmPayment.order");
                this.props.setCart(cartItems);
                this.props.setCartTotal(cartTotalOriginalPrice, cartTotalPrice);
                this.props.clearCheckout();
                this.props.setPaidOrder(order);
                this.setState({ success: true, loading: false });
              } else {
                this.setState({
                  error: "Unexpected error occurred, please contact support",
                  loading: false
                });
              }
            })
            .catch(errorResp => {
              this.setState({ error: gqErrorMsg(errorResp), loading: false });
            });
        }
      })
      .catch(error => {
        this.setState({ error, loading: false });
      });
  };

  renderQrCode = () => {
    const { t } = this.props;

    return (
      <>
        <hr />
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <h4>{t(["payment.wechatPay"])}</h4>
            <p>{t(["payment.wechatPayNote"])}</p>
            <p>{t(["payment.wechatPayOnSuccess"])}</p>
            <img src={this.props.qrCode} />
          </Col>
        </Row>
      </>
    );
  };

  render() {
    const { t } = this.props;
    if (this.state.success) {
      this.state.subscribable && this.state.subscribable.unsubscribe();
      return <PaymentSuccess />;
    }
    return (
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <h4>{t(["payment.ccPay"])}</h4>
            <Card className="custom-card">
              <Card.Body>
                <Col className={styles.total}>
                  <h2>{`${t("totalAmount")}: $${moneyStr(
                    this.props.totalPrice
                  )}`}</h2>
                </Col>
                <hr />
                <form onSubmit={this.handleSubmit}>
                  <CardElement
                    hidePostalCode
                    placeholder="Hello"
                    style={{ base: { fontSize: "22px" } }}
                  />
                  <Button
                    type="submit"
                    className={styles.confirmBtn}
                    variant="gradient"
                    disabled={this.state.loading}
                  >
                    {t("confirmPayment")}
                  </Button>
                  {this.state.loading && <GenericLoader />}
                  {this.state.error && (
                    <Alert variant="danger">{this.state.error}</Alert>
                  )}
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {this.props.qrCode && this.renderQrCode()}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    clientSecret: _.get(state.checkoutReducer, "paymentIntent.clientSecret"),
    totalPrice: _.get(state.checkoutReducer, "pendingOrder.totalPrice"),
    orderId: _.get(state.checkoutReducer, "pendingOrder.id"),
    orderNumber: _.get(state.checkoutReducer, "pendingOrder.orderNumber"),
    qrCode: _.get(state.checkoutReducer, "qrCode")
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCart,
      setCartTotal,
      clearCheckout,
      setPaidOrder
    },
    dispatch
  );

const InjectedForm = injectStripe(StripeForm);

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(InjectedForm)
);
