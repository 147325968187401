import React, { useState, useEffect } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperclip,
  faMapMarkerAlt,
  faHashtag,
  faCalendarAlt
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import LayoutSideNav from "../../LayoutSideNav";
import { GET_ORDERS } from "../../../queries/me";
import GenericLoader from "../../GenericLoader";
import {
  gqErrorMsg,
  displayDeliveryTime,
  moneyStr,
  getQueryStringValue
} from "../../../lib";
import CustomPagination from "../../CustomPagination";
import DateRangePicker from "../../DateRangePicker";
import styles from "./styles";
import history from "../../../lib/history";
import Invoice from "../../Invoice";

const Orders = ({ client, status }) => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(
    parseInt(getQueryStringValue("page"), 10) || 1
  );
  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(
    moment()
      .add(1, "day")
      .toDate()
  );

  const fetchOrders = () => {
    setSuccess(null);
    setError(null);
    client
      .query({
        query: GET_ORDERS,
        variables: { page, startDate, endDate, statuses: [status] }
      })
      .then(res => {
        setLoading(false);
        setOrders(_.get(res, "data.orders.nodes", []));
        setTotalPages(_.get(res, "data.orders.totalPages", 0));
      })
      .catch(errorResp => {
        setLoading(false);
        setError(gqErrorMsg(errorResp));
      });
  };

  const onPageChange = data => {
    const selected = _.get(data, "selected", 1) + 1;
    history.push({
      pathname: window.location.pathname,
      search: `?page=${selected}`
    });
    setPage(selected);
  };

  useEffect(() => {
    fetchOrders();
  }, [page, status]);

  return (
    <LayoutSideNav history={history}>
      <Container>
        {loading && <GenericLoader />}
        <Col>
          <DateRangePicker
            search={() => {
              setPage(1);
              fetchOrders();
            }}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <hr />
          {success && <Alert variant="success">{success}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
          {orders.map(order => (
            <Row key={order.orderNumber}>
              <Card className="custom-card">
                <Invoice order={order} />
              </Card>
            </Row>
          ))}
          <CustomPagination
            totalPages={totalPages}
            onPageChange={onPageChange}
            maxPageDisplay={3}
            page={page}
            marginPagesDisplayed={1}
          />
        </Col>
      </Container>
    </LayoutSideNav>
  );
};

export default Orders;
