import React from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";

import { LOGIN } from "../../mutations/users";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import { useDispatch } from "react-redux";
import { gqErrorMsg } from "../../lib";
import { setMe } from "../../components/Header/actions";
import GenericLoader from "../../components/GenericLoader";

const Login = ({ history }) => {
  const { t } = useTranslation();
  const {
    register,
    errors,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm();
  const dispatch = useDispatch();
  const [loginUser, { error }] = useMutation(LOGIN, {
    onCompleted(data) {
      dispatch(setMe(data));
      window.location.assign("/");
    }
  });

  const onSubmit = async data => {
    await loginUser({
      variables: { email: data.email, password: data.password }
    });
  };

  return (
    <Col md={{ span: 6, offset: 3 }}>
      <h1 className="mb-4 mt-5">{t(["login.title"])}</h1>
      <form method="POST" onSubmit={handleSubmit(onSubmit)} name="email">
        <Form.Group controlId="email">
          <Form.Label>{t(["login.email"])}</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            name="email"
            ref={register({ required: true })}
          />
          {errors.email && <p className="form-error">This is required</p>}
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>{t(["login.password"])}</Form.Label>
          <Form.Control
            type="password"
            name="password"
            ref={register({ required: true })}
          />
          {errors.password && <p className="form-error">This is required</p>}
        </Form.Group>
        <Button block type="submit" disabled={isSubmitting}>
          {t(["login.title"])}
        </Button>

        <LinkContainer to="/signup">
          <Nav.Link>{t(["login.noAccount"])}</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/forgot-password">
          <Nav.Link>{t(["login.forgotPassword"])}</Nav.Link>
        </LinkContainer>
        {isSubmitting && <GenericLoader />}
        {error && <Alert variant="danger">{gqErrorMsg(error)}</Alert>}
      </form>
    </Col>
  );
};

export default Login;
