import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";

const Cn = () => {
  return (
    <>
      <Row>
        <p>
          澳鲜（AusFreshMeat）成立于2010年，主营鸡肉，牛肉，猪肉，羊肉，鸭肉和海鲜产品。现在我们的工厂位于墨尔本Flemington区， 工厂占地面积超3000平方米。我们多年行业经验搭建了优质的供货链，其模型可确保产品从加工到储存分销的完整供应链。因此我们能更好管理产品质量，为你提供最新鲜的食材。
        </p>
        <p>
          <Card.Img src="https://res.cloudinary.com/offbyonex/image/upload/q_auto/v1585747465/afm/p1.png" />
        </p>
        <p>
          澳鲜配备了十多台的货车与司机，配送覆盖墨尔本所有区域。我们希望能为您提供完善的配送服务，也立志日后能为澳洲家庭提供优质的肉类产品和便捷服务。
        </p>
        <p>
          <Card.Img src="https://res.cloudinary.com/offbyonex/image/upload/q_auto/v1585747464/afm/p2.png" />
        </p>
        <p>
          如果您想更多地了解我们公司及产品， 可以扫码关注我们的公众号澳新食品（AusFreshMeat）。我们期待能在线解答您对我们公司及产品的疑问。有效地根据您的需求提供产品信息。同时也欢迎您的宝贵建议与意见，让我们做的更好。你可以发邮件到我们的邮 info@ausfreshmeat.com.au，期待您的来信。
        </p>
        <p>
          谢谢您对我们的关注。我们将竭尽所能为您提供优质的产品和服务。
        </p>
      </Row>
      <Row>
        <h4>新鲜的秘密</h4>
        <p>
          在寻鲜的道路上，AusFreshMeat在蜿蜒的小道上徘徊过，在错综复杂的高速上迷路过。兜兜转转后，AusFreshMeat终于找到了答案Warrook Farm，拥有一家自己的农场才能最终把控质量。在运营农场的过程中让我们意识到饲养中会有哪些问题，这样教会我们如何更好地得挑选供货商。我们农场的产品不仅是我们货源的保证而且是我们众多产品的标杆。
        </p>
        <p>
          <Card.Img src="https://res.cloudinary.com/offbyonex/image/upload/q_auto/v1585748314/afm/p4.png" />
        </p>
        <p>
          Warrook Farm位于维州的Monomeith, 占地超1500亩地。Warook Farm饲养的牛羊超300多头。我们还在探索更多的养殖选择，希望能为您提供更多不同的新鲜农产品。Warrook Farm 有自己的餐厅和宴会厅，可以举办各种活动。当然Warrook Farm有各种农场观光活动，是合家欢的好去处。 如果您有兴趣，请到我们的官网www.warrook.com.au查看详细信息。
        </p>
        <p>
          <Card.Img src="https://res.cloudinary.com/offbyonex/image/upload/q_auto/v1585748304/afm/p3.png" />
        </p>
      </Row>
    </>
  );
}

export default Cn;
