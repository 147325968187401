import React from "react";
import _ from "lodash";
import moment from "moment";
import i18n from "i18next";
import { getDay } from "date-fns";
import Ribbon from "../components/Ribbon";

// images field is autmagically turned into array of one string
// which consists of 1 or many images separated by comma
// it needs to be split
export const formNormalize = ({ data, intFields }) => {
  let formData = Object.assign({}, data);
  intFields.forEach(field => {
    formData[field] = Number(formData[field]);
  });
  return formData;
};

export const formBooleansToIds = ({ booleans, lookups }) => {
  return _.compact(
    booleans.map((b, index) => {
      if (b) {
        return lookups[index].id;
      }
    })
  );
};

export const formBooleansToValues = ({ booleans, lookups }) => {
  return _.compact(
    booleans.map((b, index) => {
      if (b) {
        return lookups[index];
      }
    })
  );
};

export const getQueryStringValue = key => {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(key);
  return value;
};

export const gqErrorMsg = errorResp => {
  const graphQLErrors = _.get(errorResp, "graphQLErrors") || errorResp;
  const msg = graphQLErrors.map(err => err.message).join(", ");
  if (_.isEmpty(msg)) {
    return "Unexpected error occurred";
  }
  return msg;
};

export const dateStr = date => moment(date).format("DD/MM/YYYY");

// https://github.com/Hacker0x01/react-datepicker/issues/1752
export const apiDateValue = (date, isDefault = false) => {
  return date
    ? new Date(date.replace(/-/g, "/"))
    : isDefault
    ? new Date()
    : null;
};

export const moneyStr = cents => {
  return ((cents || 0) / 100).toFixed(2);
};

export const displayDeliveryTime = ({ deliveryTimeFrom, deliveryTimeTo }) => {
  const [deliveryDate, fromTime] = deliveryTimeFrom.split(" ");
  const toTime = deliveryTimeTo.split(" ")[1];
  return `${dateStr(deliveryDate)} ${fromTime}-${toTime}`;
};

export const filterProducts = ({ products, filter }) => {
  if (!filter) {
    return products;
  }
  return products.filter(product =>
    product.categories.map(i => i.key).includes(filter.toLowerCase())
  );
};

export const isBizDay = date => {
  return false;
  const day = getDay(date);
  const localDate = moment(date).format("DD/MM");
  const holidays = ["25/12", "01/01"];
  return day !== 0 && !holidays.includes(localDate);
};

export const isDeliveryDay = (date, days) => {
  const day = getDay(date);
  return days.includes(day) && isBizDay(date);
};

// Deprecated
export const underPriceLimit = total => {
  return total < 4800;
};

// input cents, output cents, use moneyStr func for dollar
export const discountPrice = (originalPrice, discount) => {
  if (discount <= 0) {
    return originalPrice;
  }
  return originalPrice * ((100 - discount) / 100);
};

export const transformImage = (url, size) => {
  if (size === "lg") {
    return url.replace(/w_\d+/, "w_800");
  }
  return url;
};

export const isEn = () => i18n.language === "en";

export const translateError = (t, error) =>
  error.includes("errors.") ? t([`${error}`]) : error;

export const displayDayOfWeek = day => {
  return ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"][day];
};

export const couponValue = ({ coupon, deliveryFee, subtotal }) => {
  if (!coupon) {
    return null;
  }
  if (coupon.value > 0) {
    if (coupon.kind === "delivery") {
      if (coupon.value >= deliveryFee) {
        return deliveryFee;
      }
      return coupon.value;
    }
    return coupon.value;
  } else if (coupon.kind === "product") {
    if (_.get(coupon, "product.id")) {
      return (
        (coupon.percentage / 100) * _.get(coupon, "product.discountedPrice")
      );
    }
    return (coupon.percentage / 100) * subtotal;
  } else if (coupon.kind === "delivery") {
    return (coupon.percentage / 100) * deliveryFee;
  }
};

// output e.g. -$10 for order summary
export const couponCheckoutValue = ({ coupon, deliveryFee, subtotal }) => {
  return `-$${moneyStr(couponValue({ coupon, deliveryFee, subtotal }))}`;
};

// display for coupon list
export const couponDisplayValue = coupon => {
  if (!coupon) {
    return null;
  }
  if (coupon.value > 0) {
    return `$${moneyStr(coupon.value)}`;
  }
  if (coupon.percentage > 0) {
    return `${coupon.percentage}% off`;
  }
};

export const totalPriceWithCoupons = ({ coupons, deliveryFee, subtotal }) => {
  const derivedCouponValue = coupons.reduce((memo, coupon) => {
    return memo + couponValue({ coupon, deliveryFee, subtotal });
  }, 0);
  return deliveryFee + subtotal - derivedCouponValue;
};

// e.g. '6:0 pm'
export const minOrderDate = (deadline, deadlineMeridiem, activeFeatures) => {
  if (deadlineMeridiem === "am") {
    // missed morning deadline
    if (moment().isAfter(moment(deadline, "h:mm a"))) {
      return moment()
        .add(1, "days")
        .toDate();
    }
    return moment().toDate();
  }

  // missed evening deadline
  if (moment().isAfter(moment(deadline, "h:mm a"))) {
    return moment()
      .add(2, "days")
      .toDate();
  }
  return moment()
    .add(1, "day")
    .toDate();
};

export const minOrderDateLegacy = () => {
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  if (currentHour >= 18) {
    return moment()
      .add(2, "days")
      .toDate();
  }
  return moment()
    .add(1, "days")
    .toDate();
};

export const matchingQuantityBundle = (quantityBundles, quantity) => {
  return quantityBundles
    .sort((a, b) => b.quantity - a.quantity)
    .find(bq => bq.quantity <= quantity);
};

export const quantityBundleDiscount = (quantityBundles, quantity) => {
  const qb = matchingQuantityBundle(quantityBundles, quantity);
  return (qb && qb.percentage) || 0;
};

export const allProductsCat = {
  name: "All products",
  cn: "全部产品",
  key: "",
  icon: "",
  images: []
};

const SPECIAL_CATEGORIES = ["top-sellers", "weekly-specials", "new-products"];

export const defaultCategories = categories => {
  return (categories || [])
    .filter(c => !SPECIAL_CATEGORIES.includes(c.key))
    .map(c => {
      if (!_.isEmpty(c.images)) {
        c.icon = c.images[0];
      }
      return c;
    });
};

export const stockRibbon = (stock, t, alt = false) => {
  if (stock === 0) {
    return <Ribbon text={t("outOfStock")} alt={alt} />;
  }
  if (stock <= 10) {
    return <Ribbon text={t("limitedStock")} alt={alt} />;
  }
  return null;
};

export const locationDeadlineStr = (
  deadlineHour,
  deadlineMinute,
  deadlineMeridiem
) => {
  const paddedDeadlineMinute =
    deadlineMinute < 10 ? `0${deadlineMinute}` : deadlineMinute;

  return `${deadlineHour}:${paddedDeadlineMinute} ${deadlineMeridiem}`;
};
